import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  TextField,
  FormControl,
  Chip,
  Stack,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import TuneIcon from '@mui/icons-material/Tune';
import { NumericFormat } from 'react-number-format'

export default function ParqueEditDate() {
  const { pageTitle, setPageTitle } = useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [RBDList, setRBDList] = useState([]);
  const [rbdLoading, setRbdLoading] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(dayjs())
  const [fechaRecepcion, setFechaRecepcion] = useState(dayjs());

  const [autoCompleteInput, setAutoCompleteInput] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  const [formData, setFormData] = useState({
    rbd_list: [],
    oficio_recepcion_obras: "",
    oficio_inicio_servicio: "",
    fecha_recepcion_obras: dayjs(),
    fecha_inicio_servicio: dayjs(),
  });



  function getRBD() {
    if (autoCompleteInput !== "") {
      setRbdLoading(true);
      axios
        .getRBD(autoCompleteInput)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
          setError(false);
          setErrorMsg("");
        })
        .catch(() => {
          setRBDList([]);
          setRbdLoading(false);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

  useEffect(() => {
    if (autoCompleteInput) {
      getRBD();
    }
  }, [autoCompleteInput]);

    const handleAddRow = () => {
    if (!autoCompleteInput) {
      setError(true);
      setErrorMsg("Selecciona un RBD antes de agregar.");
      return;
    }

    if (formData.rbd_list.includes(autoCompleteInput)) {
      //setError(true);
      //setErrorMsg("El RBD ya está en la lista.");
      return;
    }

    setFormData({ ...formData, rbd_list: [...formData.rbd_list, autoCompleteInput] })
    setAutoCompleteInput(""); 
    setError(false);
    setErrorMsg("");
  };

const handleSend = (event) => {
  event.preventDefault();

  if (formData.rbd_list.length === 0) {
    return;
  }
  const updatedFormData = {
    ...formData,
    fecha_recepcion_obras: formData.fecha_recepcion_obras.toISOString(),
    fecha_inicio_servicio: formData.fecha_inicio_servicio.toISOString(),
    fecha_modificacion: dayjs().toISOString(),
  };
  console.log(formData)

  setLoading(true);

  axios
    .putOficiosRBD(updatedFormData)
    .then((response) => {
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.error('Error al enviar los datos:', error);
    });
};


    const handleDeleteChip = (chipToDelete) => {
    setFormData({ ...formData, rbd_list: formData.rbd_list.filter((chip) => chip !== chipToDelete) })
  };

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
      <PageBgBlock>
      <form onSubmit={handleSend} id="parque_date">
        <Stack alignItems="left" justifyContent="center" spacing={2}>
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
          >
            AGREGAR FECHAS INICIO SERVICIO / RxO
          </Typography>
          <Box
            justifyContent="center"
            sx={{
              maxWidth: "100%",
              margin: "0 auto",
              display: "flex",
            }}
          >
            <Typography
              variant="body"
              align="center"
              sx={{ marginBottom: "3rem", maxWidth: "45rem" }}
            >
              Para <b>agregar</b> fechas relacioandas con inicio de servicio o recepción de obras llene los campos, cuide que la información esté correcta antes de agregar.
            </Typography>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid size={4}>
            <FormControl sx={{ minWidth: "100%" }}>
              <Autocomplete
                value={autoCompleteInput}
                onChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                inputValue={autoCompleteInput}
                onInputChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                disablePortal
                loading={rbdLoading}
                loadingText="Cargando..."
                options={RBDList}
                autoComplete
                size="small"
                renderInput={(params) => (
                  <TextField
                    error={error}
                    helperText={errorMsg}
                    {...params}
                    label="RBD"
                    placeholder="Buscar RBD"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            {params.InputProps.startAdornment}
                            <TuneIcon />
                          </>
                        ),
                      }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid size={3}>

          <PageButtonSimple
                        pad=".5rem"
                        width="auto"
                        onClick={handleAddRow}
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        disabled={!autoCompleteInput || rbdLoading}
                      >
                        Agregar a la lista
                      </PageButtonSimple>


          </Grid>
        </Grid>

        

        <Divider sx={{ my: 2 }} />

        <Grid  xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Lista de RBDs"
          sx={{marginY: 1}}
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                	width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {formData.rbd_list.map((chip) => (
                  <Chip
          key={chip}
          label={chip}
          onDelete={() => handleDeleteChip(chip)}
          deleteIcon={<RemoveCircleOutlineOutlinedIcon />}
          sx={{
            backgroundColor: "rgba(61, 108, 174, 0.20)",
            "& .MuiChip-deleteIcon": {
              color: "rgba(61, 108, 174, 0.80)",
            },
            "&:hover .MuiChip-deleteIcon": {
              color: "rgba(61, 108, 174, 1)",
            },
          }}
        />
                ))}
              </Box>
            ),
          }}
        />
      </Grid>



            <Grid container spacing={2} 
          sx={{marginY: 1}}>
          <Grid size={6}>

            <FormControl sx={{ width: "100%" }} size="small">
                        
                        <NumericFormat
                                required
                                customInput={TextField}
                                label="N° de oficio recepción de obras"
                                value={formData.oficio_recepcion_obras}
                                 onValueChange={(newValue) => 
                                      setFormData((prevValues) => ({
                                        ...prevValues,
                                        oficio_recepcion_obras: newValue.value, 
                                      }))
                                    }
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />

                        </FormControl>

          </Grid>
          <Grid size={6}>

              <FormControl sx={{ width: "100%" }} size="small">

              <NumericFormat
                                required
                                customInput={TextField}
                                label="N° de oficio inicio de servicio"
                                value={formData.oficio_inicio_servicio}
                                 onValueChange={(newValue) => 
                                      setFormData((prevValues) => ({
                                        ...prevValues,
                                        oficio_inicio_servicio: newValue.value, 
                                      }))
                                    }
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />

                  </FormControl>

          </Grid>
          <Grid size={6}>
                  <FormControl fullWidth variant="outlined" sx={{marginY: 1}}>
                  <LocalizationProvider 
                      dateAdapter={AdapterDayjs}
                      adapterLocale={'es'}
                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DateTimePicker
                          label="Oficio recepción de obras (devengo) "
                          slotProps={{ textField: { size: 'small' } }}
                          value={formData.fecha_recepcion_obras}
                          size="small"
                          sx={{ width: "100%" }}
                          onChange={(newValue) => setFormData({ ...formData, fecha_recepcion_obras: newValue })}

                      />
                  </LocalizationProvider>
                  </FormControl>

          </Grid>

          <Grid size={6}>
                  <FormControl fullWidth variant="outlined" sx={{marginY: 1}}>
                  <LocalizationProvider 
                      dateAdapter={AdapterDayjs}
                      adapterLocale={'es'}
                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DateTimePicker
                          label="Oficio de inicio de servicio (facturación)"
                          slotProps={{ textField: { size: 'small' } }}
                          value={formData.fecha_inicio_servicio}
                          size="small"
                          sx={{ width: "100%" }}
                          onChange={(newValue) => setFormData({ ...formData, fecha_inicio_servicio: newValue })}

                      />
                  </LocalizationProvider>
                  </FormControl>

          </Grid>
        </Grid>


         <Box sx={{margin: "auto", marginTop: "2rem"}}>
            <PageButtonSimple
                        form={"parque_date"}
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        loading={loading}
                        disabled={formData.rbd_list.length === 0}
                        style="success"
                      >
                        Agregar fechas
                      </PageButtonSimple>
          </Box>
          </form>
      </PageBgBlock>
    </>
  );
}
