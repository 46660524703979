import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import TablePagination from "@mui/material/TablePagination";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import ListEmpty from "../GeneralComponents/ListEmpty";
import axios from "../../api/axios";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Modal from '@mui/material//Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";

import PrefacturaCard from "./PrefacturaCard";
import PrefacturaDetails from "./PrefacturaDetails";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import {ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_OP_ADMIN, ROL_ADMIN, PERIODS} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);



export default function PrefacturaList() {
    const [page, setPage] = useState(0);
    const [pageDev, setPageDev] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rows, setRows] = useState([]);
  const [rowsDev, setRowsDev] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [filtersDev, setFiltersDev] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [tableDataDev, setTableDataDev] = useState(rows);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableDev, setLoadingTableDev] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filteredColumnsDev, setFilteredColumnsDev] = useState([]);
  const navigate = useNavigate();



  const [activeRow, setActiveRow] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row) => {setActiveRow(row); setOpenModal(true); }
  const handleCloseModal = () => setOpenModal(false);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getPrefacturas(query)
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            diff: row["diferencia"] ? "CON DIFERENCIAS" : "SIN DIFERENCIAS",
            color: row["diferencia"] ? "#C489D9" : "#E6E6E6",
            codigo: row["codigo prefactura"] ? row["codigo prefactura"] : "-",
            diff_action: "",
            periodo: row.periodo.slice(0, 7),
            fecha_prefactura: row.periodo.slice(0, 10),
            estado_validacion: row["estado validacion"] ? row["estado validacion"] : "Pendiente",
            index: index + 1,
            
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {


        setLoadingTable(false);
      });

}

const fetchDataDev = async (query) => {
    setLoadingTableDev(true);
    axios
      .getPrefacturas(query)
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            diff: row["diferencia"] ? "CON DIFERENCIAS" : "SIN DIFERENCIAS",
            color: row["diferencia"] ? "#C489D9" : "#E6E6E6",
            codigo: row["codigo prefactura"] ? row["codigo prefactura"] : "-",
            diff_action: "",
            periodo: row.periodo.slice(0, 7),
            fecha_prefactura: row.periodo.slice(0, 10),
            estado_validacion: row["estado validacion"] ? row["estado validacion"] : "Pendiente",
            index: index + 1,
            
          };
        });
        setRowsDev(modifiedData);
        setTableDataDev(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {


        setLoadingTableDev(false);
      });

}


const acceptPrefacture = () => {
  handleCloseModal();

  axios
      .acceptPrefactura(activeRow["id"])
      .then((response) => {
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
        fetchData();
      });

  
}

  useEffect(() => {
    fetchData(PERIODS[0]);
    fetchDataDev(PERIODS[0]);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    const refreshData = (name: String, value: any) => {
    if(value){
      fetchData(value);
    fetchDataDev(value);
    }
    
  };
  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };


  const handleChangePageDev = (event, newPage) => {
    setPageDev(newPage);
  };


  const filterRecordsDev = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFiltersDev(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableDataDev([
        ...rowsDev.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableDataDev(rowsDev);
    }
  };

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
    <PageBody>

      <Grid
        container
        spacing={1}
        columns={10}
        sx={{ marginBottom: "28px", marginTop: "-1rem" }}
      >
        <Grid size={2}>
          <InputFilter
            opt={PERIODS}
            data={value === 1 ? rows : rowsDev}
            value={PERIODS[0]}
            item={"periodo"}
            name={"Periodo"}
            callback={refreshData}
          />
        </Grid>
        {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN]) ? (<Grid size={2}>
          <InputFilter
            data={rows}
            item={"id_operador"}
            name={"Operador"}
            callback={value === 1 ? filterRecords : filterRecordsDev}
          />
        </Grid>) : null}
        <Grid size={2}>
          <InputFilter
            data={value === 1 ? rows : rowsDev}
            item={"diff"}
            name={"Diferencia"}
            callback={value === 1 ? filterRecords : filterRecordsDev}
          />
        </Grid>
        <Grid size={2}>
          <InputFilter
            data={value === 1 ? rows : rowsDev}
            item={"estado_validacion"}
            name={"Estado"}
            callback={value === 1 ? filterRecords : filterRecordsDev}
          />
        </Grid>
      </Grid>
      <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
                sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: value === "1" ?  theme => theme.palette.primary.main : "#FE9D7E", 
            },
          }}
              >
                <Tab label="Facturación" value="1" sx={{ "&.Mui-selected": {  color: "white",  backgroundColor: "#1976D21A", color: theme => theme.palette.primary.main} }}/>
                <Tab label="Devengo" value="2" sx={{ "&.Mui-selected": {  color: "white",  backgroundColor: "#FE9D7E99", color: "#111", } }}/>
              </TabList>
            </Box>
            <TabPanel value="1">

      {loadingTable ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>

        {tableData.length === 0 ? (<ListEmpty/>) : null}
        <Box display="flex" flexWrap="wrap" justifyContent="left">
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <PrefacturaCard key={index} data={data} type="Devengo" fetch={fetchData}/>
              ))}
          </Box>

          <TablePagination
            component="div"
            count={tableData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20]}
            labelRowsPerPage="Elementos por página:"
          />



        </>
      )}

      </TabPanel>

            <TabPanel value="2">

              {loadingTableDev ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>

        {tableDataDev.length === 0 ? (<ListEmpty/>) : null}
        <Box display="flex" flexWrap="wrap" justifyContent="left">
            {tableDataDev
              .slice(pageDev * rowsPerPage, pageDev * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <PrefacturaCard key={index} data={data} fetch={fetchData}/>
              ))}
          </Box>

          <TablePagination
            component="div"
            count={tableDataDev.length}
            page={page}
            onPageChange={handleChangePageDev}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20]}
            labelRowsPerPage="Elementos por página:"
          />



        </>
      )}
            </TabPanel>
          </TabContext>



      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        slotProps={{ backdrop: { sx: { opacity: "0.1!important" } } }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "749px",
            height: "261px",
            backgroundSize: "background.paper",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>

      
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
              sx={{
                fontSize: "26px",
                lineHeight: "66px",
                margin: "0",
                color: "#333",
              }}
            >
              ¿Desea continuar?
            </Typography>

            <Box justifyContent="center" sx={{ maxWidth: "400px", margin: "0 auto", display: "flex" }} >
              <Typography variant="body" align="center" >
              Esta por aceptar la prefactura calculada por SAGEC para la <b>zona {activeRow["id_zona"]}</b> en el periodo <b>{activeRow["periodo_facturacion"]}</b>. Una vez aceptada, esta accion no podrá ser revertida.
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ paddingTop: "2rem" }}>
              <Grid size={3}></Grid>
                <Grid size={3}>

            <PageButtonWhite width="10rem" onClick={handleCloseModal}>
              Cancelar
            </PageButtonWhite>

          </Grid>
                <Grid size={3}>
            <PageButtonBlack width="10rem" onClick={acceptPrefacture}>
              Aceptar prefactura
            </PageButtonBlack>
          </Grid>
          </Grid>
          </Paper>
        </Box>
      </Modal>

    </PageBody>
    </>
  );
}
