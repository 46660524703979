import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import axios from "../../api/axios";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputRut from "../GeneralComponents/InputRut";
import {  Button, InputAdornment, } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ApelacionHiddenPreview from "./ApelacionHiddenPreview";

import InputFake from "../GeneralComponents/InputFake";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_VAL_MINEDUC} from "../../Constants/Constants";

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ApelacionPreview() {
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =  useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);


  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const location = useLocation();
  const { id } = useParams();
  const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado apelaciones', path: '/apelaciones_lista' },
    { name: 'Detalle apelación', path: '/apelacion_detalle/'+id },
  ];


   const [formData, setFormData] = useState({
    id_operador: "",
    operator_name: "",
    id_zona: "",
    periodo: "",
    description: "",
    evidence: "",
  });



const PathSegment = () => {
  const location = useLocation();
  const segments = location.pathname.split('/').filter(Boolean);
  let path = segments.length > 1 ? segments[segments.length - 2] : null;
      path = path.startsWith("apelacion") ? "apelacion" : "devengo";
  return path;
};

const type = PathSegment();
const fetchData = async (query) => {
  setLoading(true);
  try {
    const api_get = type === "apelacion" ? axios.getApelacion(id) : axios.getDevengo(id);

    const response = await api_get;

    setFormData(response.data.formulario_datos);
    setDocumentVisible(true);
  } catch (error) {
    setDocumentVisible(false);
    console.error("Error al obtener los datos:", error);
  } finally {
    setLoading(false);
  }
};



  useEffect(() => {
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);


  if (pageLoaded) {
    return (
        <>
          <PageBody>
          <BreadcrumbStandard links={links} />
        {loading ? (
        <PageBgBlock pad="2rem 9rem">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
        </PageBgBlock>

        ) : (
      documentVisible || true ? 


      <>
      <PageBgBlock pad="2rem 9rem" >
      <ApelacionHiddenPreview formData={formData}  id={id} type={type}/>

        <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, marginBottom: 2 }}
            >
              JUSTIFICACIÓN DE SUBTEL
            </Typography>
          <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                disabled
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Escriba aquí la justificación"
                size="small"
                value={formData.comentario}
               
              />
            </FormControl>


      </PageBgBlock>
      </>
       :

        <PageBgBlock pad="4rem 9rem">

            <Box align="center">
              <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
            </Box>
            <Typography
            align="center"
              variant="h1"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
            >
              No disponible
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                El documento al que intenta acceder no esta diponible para ser firmado.
              </Typography>
            </Box>
            </PageBgBlock>
      
    )}
   
    </PageBody>
              </>
    );
  }
}
