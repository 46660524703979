import React from 'react';
import {Typography, Box} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PageBgBlock from "./PageBgBlock";

const PageError = ({children}) => {

  return (
    <PageBgBlock pad="4rem 9rem">
              <Box align="center">
                <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
              </Box>
              <Typography
                align="center"
                variant="h1"
                component="h1"
                fontWeight="bold"
                color="textPrimary"
              >
                No disponible
              </Typography>

              <Box
                justifyContent="center"
                sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
              >
                <Typography
                  variant="body"
                  align="center"
                  sx={{ marginBottom: "20px" }}
                >
                  {children}
                </Typography>
              </Box>
            </PageBgBlock>
  );
};

export default PageError;
