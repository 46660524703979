import dayjs from 'dayjs';

const generateFirstDayDates = (startYear) => {
  const currentYear = dayjs().year(); 
  const currentMonth = dayjs().month(); 
  const dates = [];

  for (let year = startYear; year <= currentYear; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === currentYear && month > currentMonth) break;

      const date = dayjs().year(year).month(month).date(1);
      dates.push(date.format('YYYY-MM')); 
    }
  }

  return dates.sort((a, b) => dayjs(b, 'YYYY-MM').diff(dayjs(a, 'YYYY-MM')));
};


export const PERIODS = generateFirstDayDates(2011);

export const NO_EES = "Falta Firma EES";
export const NO_EES_TEMP = "Falta Firma EES Temporal";
export const NO_SUBTEL = "Falta firma SUBTEL";
export const NO_MINEDUC = "Falta firma MINEDUC";
export const APROVED = "Aprobado";
export const REJECTED = "Rechazado";
export const PENDING = "En Proceso";

export const ROL_ADMIN = 1;
export const ROL_OP_ADMIN = 3;
export const ROL_OP_INS = 4;
export const ROL_REP_EES = 5;
export const ROL_REP_EES_TEMP = 11;
export const ROL_ADMIN_SUBTEL = 6;
export const ROL_VAL_SUBTEL = 7;
export const ROL_CON_MINEDUC = 8;
export const ROL_MINEDUC_ADMIN = 9;

export const QUESTIONS = [
  "¿Usted está de acuerdo con la medición de velocidad (speed test) realizada por el técnico instalador?",
  "¿Es satisfactoria para usted la instalación física del equipamiento en general del servicio?",
  "¿Usted recibió indicaciones claras del técnico instalador de qué hacer ante anomalías del servicio?",
  "¿Usted recibió material de apoyo por parte del técnico instalador?",
];

export const REJECT_F = [
"EES no cuenta con disponibilidad de servicio o sin tráfico de datos de acuerdo a la información del monitoreo en línea, en el momento de la revisión.",
"No adjuntan imágenes de Speed Test, pérdida de paquetes y equipos instalados.",
"No cumple con las velocidades adjudicadas.",
]


export const DAYS = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];


//import {NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_ADMIN, ROL_OP_ADMIN, ROL_OP_INS, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_VAL_SUBTEL, ROL_CON_SUBTEL, ROL_MINEDUC_ADMIN} from "../Constants/Constants";