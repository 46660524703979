import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TuneIcon from '@mui/icons-material/Tune';
import TextField from '@mui/material/TextField';

const textFieldStyles = {
  width: '100%',
};

export default function InputFilter(props) {
  const [itemName] = useState(props.item);
  const [rows, setRows] = useState(props.data);
  const [options, setOptions] = useState([...new Set(rows.map(item => item[itemName]))]);
  const [value, setValue] = useState(props.value);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false); 

  const icon = props.icon ? props.icon : <TuneIcon />;

  useEffect(() => {
    var opt = []
    if (props.opt){
      opt = props.opt;
    }else{
      opt = [...new Set(props.data.map(item => item[itemName]))];
    }

    if (opt.slice().sort().join(",") !== options.slice().join(",")) {
      setRows(props.data);
      setOptions(opt);
    }
  }, [props.data]);



  return (
    <FormControl sx={textFieldStyles} size="small">
      <Autocomplete
        size="small"
        value={value || null}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.callback(itemName, newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.name}
            InputLabelProps={{
              shrink: isFocused || inputValue.length > 0 ,
              sx: {
                marginLeft: inputValue.length > 0  || isFocused ? 0 : "24px", // Ajusta la posición del label hacia la derecha
              },
            }}
            slotProps={{
              input: {
                ...params.InputProps,
                startAdornment: icon,
              },
            }}
            onFocus={() => setIsFocused(true)}  // Activar shrink cuando el campo tiene foco
            onBlur={() => setIsFocused(false)}   // Desactivar shrink cuando pierde el foco
          />
        )}
      />
    </FormControl>
  );
}
