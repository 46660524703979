import React, { useState, useContext, useEffect } from "react";
import { Box, MenuItem, Select, Checkbox, ListItemText, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid2";

import axios from "../../api/axios";
import GeneralContext from "../GeneralContext/GeneralContext";

import PageBody from "../GeneralComponents/PageBody";

export default function OPReport() {
  const [rows, setRows] = useState([]);
  const { userSesion } = useContext(GeneralContext);
  const [zona, setZona] = useState([]);
  const [rbd, setRbd] = useState([]);
  const [operador, setOperador] = useState("");
  const [from, setFrom] = useState("now-1M");
  const [to, setTo] = useState("now");
  const baseUrl =
    "/service/grafana/d-solo/be0kjiscwhzwga/reporte-supervision";

  // Construye los parámetros dinámicamente
  const params = new URLSearchParams({
    "var-Operador": operador,
    "panelId": "11",
  });

  zona.forEach((z) => params.append("var-Zona", z));
  rbd.forEach((id) => params.append("var-RBD", id));

  const iframeSrc = `${baseUrl}?orgId=1&${params.toString()}`;

  // Obtener operador
  const getOperator = async () => {
    try {
      const response = await axios.getOperator(userSesion.id);
      setOperador(response.data.operador);
    } catch (error) {
      console.error("Error en getOperator:", error);
    }
  };

  // Obtener datos de zonas
  const fetchData = async () => {
    try {
      const response = await axios.getAllZonas();
      setRows(response.data);
    } catch (error) {
      console.error("Error en fetchData:", error);
    }
  };

  // Manejar cambios en las zonas seleccionadas
  const handleZonaChange = (event) => {
    setZona(event.target.value);
  };

  // Manejar cambios en los RBD seleccionados
  const handleRbdChange = (event) => {
    setRbd(event.target.value);
  };

  useEffect(() => {
    fetchData();
    getOperator();
  }, []);

  return (
    <PageBody>
      <Box width="100%">
        <Grid container spacing={1} sx={{ marginBottom: "28px", marginTop: "-2rem" }}>
          {/* Selector múltiple para Zona */}
          <Grid size={3}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              multiple
              displayEmpty
              value={zona}
              onChange={handleZonaChange}
              renderValue={(selected) =>
                selected.length === 0 ? "Seleccionar Zona" : selected.join(", ")
              }
            >
              {rows.map((row) => (
                <MenuItem key={row.nombre_zona} value={row.nombre_zona}>
                  <Checkbox checked={zona.indexOf(row.nombre_zona) > -1} />
                  <ListItemText primary={<Typography  sx={{color: "black!important" }} >{row.nombre_zona}</Typography>} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>


          <Grid size={3}>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              multiple
              displayEmpty
              value={rbd}
              onChange={handleRbdChange}
              renderValue={(selected) =>
                selected.length === 0 ? "Seleccionar RBD" : selected.join(", ")
              }
              sx={{ width: "100%" }}
            >
              {rows.map((row) => (
                <MenuItem key={row.rbd} value={row.rbd}>
                  <Checkbox checked={rbd.indexOf(row.rbd) > -1} />
                  <ListItemText primary={<Typography  sx={{color: "black!important" }} >{row.rbd}</Typography>} />
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Iframe dinámico */}
        <iframe
          key={iframeSrc} 
          src={iframeSrc}
          width="100%"
          height="600"
          frameBorder="0"
          title="Dynamic Iframe"
        ></iframe>
      </Box>
    </PageBody>
  );
}
