import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";


import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";


import { ROL_VAL_MINEDUC, ROL_REP_EES, ROL_VAL_SUBTEL} from "../../Constants/Constants";


export default function EmbeddedAdminGrid() {
  const { includeRol, userSesion } = useContext(GeneralContext);

  return (


    <>
      <Grid container spacing={1} sx={{ padding: "0" }}>
          <Grid size={12} sx={{display: "block", minHeight: "400px"}}>
            <iframe width="100%" height="100%" allowFullScreen src="/service/grafana/d-solo/ce3frptw5hfy8d/estado-bd?orgId=1&from=now-1M&to=now&panelId=12" frameBorder="0"></iframe>
          </Grid>
          

      </Grid>
  
    </>
  );
}

