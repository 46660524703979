import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";
import React, { useState } from 'react';


function validarRut(rut) {
    rut = rut.replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '').toUpperCase();
    if (!/^\d{7,8}[0-9K]$/.test(rut)) {
        return false;
    }

    const aux = rut.slice(0, -1);
    const dv = rut.slice(-1);

    const reverted = Array.from(aux).reverse().map(Number);

    const factors = [2, 3, 4, 5, 6, 7];
    let sum = 0;

    for (let i = 0; i < reverted.length; i++) {
        sum += reverted[i] * factors[i % factors.length];
    }

    const res = (11 - (sum % 11)) % 11;

    if (res === 11) return dv === "0";
    if (res === 10) return dv === "K";
    return dv === res.toString();
}


function formatearRut(rut) {
    rut = rut.replace(/^0+/, "").replace(/\./g, "").replace(/-/g, "").replace(/\s+/g, '');
    const cuerpo = rut.slice(0, -1);
    const dv = rut.slice(-1);
    if (rut.length > 1){
    	return cuerpo + "-" + dv;
    }else{
    	return cuerpo+dv
    }
}



export default function InputRut(props) {

    const [error, setError] = useState(false);

    const handleRutChange = (e) => {
        const input = e.target.value;
        const isValid = validarRut(input);
        props.errorRut(!isValid && props.checked);

        props.handleRut(formatearRut(input));
    };

    return (
        <FormControl sx={{ width: "100%" }}>
            <Grid container sx={{m: props.margin ? props.margin : 1, width: "100%", background: props.checked ? "#BBDEFB" : "#E4E4E4", borderRadius: "0.5rem", padding: "2%" }} spacing={2}>
                <Grid size={6} >
                    <Grid container spacing={2}>
                        <Grid sx={{ width: "100%" }} size={12} >
                            <FormControlLabel
                                control={
                                    <Switch checked={props.checked} onChange={props.handleSwitch} />
                                }
                                label="Administrador temporal"
                            />
                        </Grid>
                        <Grid sx={{ width: "100%" }} size={12} >
                            <TextField
                                required
                                id="outlined-required"
                                label="RUT Administrador temporal"
                                size="small"
                                helperText={props.error && props.checked ? "RUT inválido" : "(12345678-9)"}
                                error={props.error && props.checked} 
                                value={props.rut}
                                disabled={!props.checked}
                                sx={{
                                    width: "100%",
                                }}
                                onChange={handleRutChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={6}>
                    <Typography
                        color="inherit"
                        sx={{ fontSize: "12px" }}
                    >
                        *Si por fuerza mayor el administrador del colegio no se encuentra en el establecimiento debe ingresar a un Administrador temporal que cumpla el rol de ministro de fe del proceso y debe contar con Clave Única para firmar el documento.
                    </Typography>
                </Grid>
            </Grid>
        </FormControl>
    );
}