import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Paper,
  Divider,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import TuneIcon from '@mui/icons-material/Tune';
import { NumericFormat } from 'react-number-format'

export default function ParqueDelete() {
  const { pageTitle, setPageTitle } = useContext(GeneralContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [RBDList, setRBDList] = useState([]);
  const [rbdLoading, setRbdLoading] = useState(false);
  const [oficio, setOficio] = useState("");
  const [oficioFecha, setOficioFecha] = useState(dayjs());

  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const [eesData, setEesData] = useState(null);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [emptyTable, setEmptyTable] = useState(false);

  // Obtiene los datos de EES por RBD
  function getEes() {
    if (autoCompleteInput !== "") {
      axios
        .getEesParque(autoCompleteInput)
        .then((response) => {
          setEesData(response.data);
          setError(false);
          setErrorMsg("");
        })
        .catch(() => {
          setEesData(null);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

const handleDeleteRow = (index) => {
  setRows((prevRows) => {
    const updatedRows = prevRows.filter((_, i) => i !== index);
    return updatedRows;
  });
};

  function getRBD() {
    if (autoCompleteInput !== "") {
      setRbdLoading(true);
      axios
        .getRBD(autoCompleteInput)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
          setError(false);
          setErrorMsg("");
          getEes();
        })
        .catch(() => {
          setRBDList([]);
          setRbdLoading(false);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

  useEffect(() => {
    if (autoCompleteInput) {
      getRBD();
    }
  }, [autoCompleteInput]);

const handleAddRow = () => {
    if (!eesData || error) {
      alert(
        "No se pueden agregar datos. Verifique que el establecimiento exista y que no haya errores."
      );
      return;
    }

    const alreadyExists = rows.some((row) => row.rbd === eesData.rbd);
    setEmptyTable(false);
    if (alreadyExists) {
      return;
    }

    setRows((prevRows) => [
      ...prevRows,
      {
        rbd: eesData.rbd,
        nombre_colegio: eesData.nombre_colegio,
        nombre_operador: eesData.nombre_operador,
        id_zona: eesData.id_zona,
        motivo_baja: "",
      },
    ]);

    setAutoCompleteInput("");
    setEesData(null);
    setError(false);
  };

  const handleMotivoChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].motivo_baja = value;
    setRows(updatedRows);
  };

  const handleSend = (event) => {
    event.preventDefault();
    if (rows.length === 0) {
      setEmptyTable(true);
      return;
    }
    
 
      const updateEesState = async () => {
      setLoading(true); 
      const updatedRows = await Promise.all(
        rows.map(async (row) => {
          try {
            const requestData = {
            rbd: row.rbd,
            motivo_baja: row.motivo_baja || "No especificado", 
            numero_oficio: oficio, 
            fecha_oficio: oficioFecha.toISOString(),
          };


            await axios.putBajaByRBD(row.rbd, requestData); 
            return { ...row, procesoExitoso: true }; 
          } catch (error) {
            console.error(`Error al procesar el RBD ${row.rbd}:`, error);
            return { ...row, procesoExitoso: false }; 
          }
        })
      );
      //setRows(updatedRows);
      setRows([]);
      setLoading(false); 
    };
    updateEesState();
    
  };

  const removeEES = () => {
    setLoading(true);
    alert("Eliminar establecimiento");
    setLoading(false);
  };

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
      <PageBgBlock>
                <form onSubmit={handleSend} id="parque_remove">

        <Stack alignItems="left" justifyContent="center" spacing={2}>
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
          >
            ELIMINAR EES DEL PARQUE
          </Typography>
          <Box
            justifyContent="center"
            sx={{
              maxWidth: "100%",
              margin: "0 auto",
              display: "flex",
            }}
          >
            <Typography
              variant="body"
              align="center"
              sx={{ marginBottom: "3rem", maxWidth: "45rem" }}
            >
              Para <b>eliminar</b> establecimientos al Estado de Parque busque
              el número de RBD, al tener el resultado presione Eliminar.
            </Typography>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid size={4}>
            <FormControl sx={{ minWidth: "100%" }}>
              <Autocomplete
                value={autoCompleteInput}
                onChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                inputValue={autoCompleteInput}
                onInputChange={(event, newValue) => setAutoCompleteInput(newValue || "")}
                disablePortal
                loading={rbdLoading}
                loadingText="Cargando..."
                options={RBDList}
                autoComplete
                size="small"
                renderInput={(params) => (
                  <TextField
                    error={error}
                    helperText={errorMsg}
                    {...params}
                    label="RBD" placeholder="Buscar RBD"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {params.InputProps.startAdornment}
                          <TuneIcon />
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid size={3}>

          <PageButtonSimple
                        pad=".5rem"
                        width="auto"
                        onClick={handleAddRow}
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        disabled={!autoCompleteInput || rbdLoading}
                      >
                        Agregar a la lista
                      </PageButtonSimple>


          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell sx={{width: "80px"}}>
                      <IconButton
                        onClick={() => {
                              setRows([]);
                            }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                <TableCell>RBD</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Operador</TableCell>
                <TableCell>ID Zona</TableCell>
                <TableCell>Motivo Baja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>

                    <TableCell>
                        <IconButton
                          onClick={() => handleDeleteRow(index)}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                  <TableCell sx={{ verticalAlign: "middle" }}>{row.rbd}</TableCell>
                  <TableCell sx={{ verticalAlign: "middle" }}>{row.nombre_colegio}</TableCell>
                  <TableCell sx={{ verticalAlign: "middle" }}>{row.nombre_operador}</TableCell>
                  <TableCell sx={{ verticalAlign: "middle" }}>{row.id_zona}</TableCell>
                  <TableCell>
                    <TextField
                      required
                      multiline
                      minRows={2}
                      maxRows={4}
                      size="small"
                      sx={{ width: "100%" }}
                      value={row.motivo_baja}
                      onChange={(e) => handleMotivoChange(index, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length === 0 && emptyTable ? (
                <Typography
                  variant="small"
                  color="error"
                  sx={{ flexGrow: 1 }}
                >
                  No hay elementos en la tabla.
                </Typography>
                ) : null}

        <Divider sx={{ my: 2 }} />





            <Grid container spacing={2}>
          <Grid size={6}>
          <Stack>
            <FormControl fullWidth variant="outlined" sx={{marginY: 1}}>
                    <NumericFormat
                                required
                                customInput={TextField}
                                label="N° de Oficio"
                                value={oficio}
                                onValueChange={(newValue) => setOficio( newValue.value)}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />
                    
                  </FormControl>



                  <FormControl fullWidth variant="outlined" sx={{marginY: 1}}>
                  <LocalizationProvider 
                      dateAdapter={AdapterDayjs}
                      adapterLocale={'es'}
                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                      <DateTimePicker
                          label="Fecha oficio"
                          slotProps={{ textField: { size: 'small' } }}
                          value={oficioFecha}
                          size="small"
                          sx={{ width: "100%" }}
                          onChange={(newValue) => setOficioFecha(dayjs(newValue))}
                          disableFuture
                      />
                  </LocalizationProvider>
                  </FormControl>
          </Stack>


          </Grid>
          <Grid size={6}>
              <Typography
                    variant="small"
                  >
                  *Por favor, en el campo “N° Oficio” ingrese el número del oficio recibido por correo. Este número es importante para fines administrativos y debe registrarse correctamente. Ante cualquier duda, no dude en contactarnos a través de los canales oficiales.
                  </Typography>
            
          </Grid>
        </Grid>


         <Box sx={{margin: "auto", marginTop: "2rem"}}>
            <PageButtonSimple
                        form="parque_remove"
                        disabled={rows.length === 0}
                        startIcon={<RemoveCircleOutlineOutlinedIcon/>}
                        loading={loading}
                        style="warning"
                      >
                        Eliminar establecimiento (Baja EES)
                      </PageButtonSimple>
          </Box>
          </form>
      </PageBgBlock>
    </>
  );
}
