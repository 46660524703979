import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GeneralContext from '../GeneralContext/GeneralContext';
import Grid from '@mui/material/Grid2';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { NumericFormat } from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from "../../api/axios";
import Alert from '@mui/material/Alert';
import { Divider } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import InputRut from "../GeneralComponents/InputRut";

import ModalStandard from "../GeneralComponents/ModalStandard";
import HourglassLoader from "../GeneralComponents/HourglassLoader";
import { useNavigate } from "react-router-dom";
import FasHiddenPreview from "./FasHiddenPreview";
import PageBody from "../GeneralComponents/PageBody";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function FasForm() {

    const { setPageTitle, setDrawerHidding, userSesion } = useContext(GeneralContext);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [techTypes, setTechTypes] = useState([]);
    const [RBDList, setRBDList] = useState([""]);
    const [submitConfirm, setSubmitConfirm] = useState(false);
    const [rbdLoading, setRbdLoading] = React.useState(false);
    const [autoCompleteInput, setAutoCompleteInput] = React.useState("");
    const fasRef = useRef();
    const autoCompleteRef = useRef(null);
    const rutRef = useRef(null);
    const evidenceRef = useRef(null);   
    const [evError, setEvError] = useState(true);    
    const [error, setError] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');
    const [errorRut, setErrorRut] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [modalError, setModalError] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loadingPdf, setLoadingPdf] = useState(false);

    const [errors, setErrors] = useState({});

    const validation_data = {"tech_id": "",
            "int_speed_down": 500,
            "speed_up": 3,
            "speed_down": 1000,
            "latency": 10,
            "packet_loss": 2,}

    const [formData, setFormData] = useState(
        {
            "rut": userSesion.id,
            "operator_name": "",
            "rbd": "",
            "ees_name": "",
            "ees_managers": ["", ""],
            "ees_temporal_manager_list": [""],
            "ees_temporal_manager": "",
            "tech_id": "",
            "int_speed_down": validation_data.int_speed_down,
            "installation_date": dayjs(),
            "speed_up": validation_data.speed_up,
            "speed_down": validation_data.speed_down,
            "latency": validation_data.latency,
            "packet_loss": validation_data.packet_loss,
            "checked": false,
            "images": [],
            "validation_data": validation_data,
        }
    )

    const handleErrorChange = (error) => {
        setErrorRut(error);
        console.log(error)
    };


    const validateField = (field, value) => {
    let error = "";

    switch (field) {
      case "int_speed_down":
        if (value < 100) error = "La velocidad de descarga debe ser mayor a 100.";
        if (value > 1000) error = "La velocidad de descarga no puede superar 1000.";
        break;

      case "speed_up":
        if (value <= 0) error = "La velocidad de subida debe ser mayor a 0.";
        break;

      case "speed_down":
        if (value < 500) error = "La velocidad mínima de bajada es 500.";
        break;

      case "latency":
        if (value < 1 || value > 100) error = "La latencia debe estar entre 1 y 100.";
        break;

      case "packet_loss":
        if (value < 0 || value > 5) error = "La pérdida de paquetes debe estar entre 0 y 5.";
        break;

      default:
        break;
    }

    return error;
  };



const handleDownloadPdf = () => {
    setLoadingPdf(true);


    setTimeout(() => {
    const element = fasRef.current;

    html2canvas(element, { scale: 2 })
        .then((canvas) => {
            //const imageData = canvas.toDataURL('image/png');
            const imageData = canvas.toDataURL('image/jpeg', 1);
           
            const pdfWidth = 210;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 7]);

     
            pdf.setFillColor(255, 255, 255);
            pdf.rect(0, 0, pdfWidth, pdfHeight + 140, 'F');

            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);


            const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                installation_date: formData["installation_date"].locale('es').format('YYYY/MM/DD HH:mm:ss').toString(),
                rbd: autoCompleteInput ? autoCompleteInput : "" ,
            }

            form.append("data", JSON.stringify(localFormData));
            for (let i = 0; i < localFormData["images"].length; i++) {
                form.append("evidence", localFormData["images"][i]);
            }
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesFas(form)
                .then((response) => {
                    setLoading(false);
                    setSubmitConfirm(!submitConfirm);
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        setLoadingPdf(false);
        setLoadingButton(false);
        })
        .catch((error) => {
            console.error('Error generando el PDF:', error);
            setLoadingPdf(false);
            setLoadingButton(false);
            throw error;
        });
        }, 0);
};

const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const uniqueFiles = files.filter(
        (file) => !formData.images.some((img) => img.name === file.name)
    ); // Evitar duplicados basados en el nombre del archivo
    setFormData({
        ...formData,
        images: [...formData.images, ...uniqueFiles],
    });
    setEvError(false);

    e.target.value = null;
};

const handleRemoveImage = (imageName) => {
    const updatedImages = formData.images.filter((image) => image.name !== imageName);
    setFormData({
        ...formData,
        images: updatedImages,
    });
    if (updatedImages.length === 0) {
        setEvError(true);
    }
};

function getEes() {
    if (autoCompleteInput !== "") {
        const rbd_temp = autoCompleteInput;
        axios.getEesData(rbd_temp, "1")
            .then((response) => {
                const tempRep =
                    response.data.nombres_representantes.length === 1
                        ? [response.data.nombres_representantes[0], "Sin representante"]
                        : response.data.nombres_representantes;

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ees_name: response.data.nombre_colegio,
                    ees_managers: tempRep.length > 0 ? tempRep : ["Sin representante", "Sin representante"],
                    ees_temporal_manager_list:
                        response.data.nombres_representantes_temporales.length > 0
                            ? response.data.nombres_representantes_temporales
                            : ["Sin representante"],
                }));

                setError(false);
                setErrorMessage("");
            })
            .catch((error) => {
                console.error("Error al obtener datos del EES:", error);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ees_name: "Colegio no encontrado",
                    ees_managers: ["Colegio no encontrado", "Colegio no encontrado"],
                    ees_temporal_manager_list: ["Sin representante"],
                }));

                setError(true);
                setErrorMessage("Colegio no encontrado");
            });
    }
}


    function getTechType() {
        axios.getTechTypes()
            .then((response) => {
                setTechTypes(response.data)
            })
            .catch((error) => {
            })
    }
    function getRBD() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            setRbdLoading(true)
            axios.getRBD(rbd_temp)
                .then((response) => {
                    setRBDList(response.data.rbd_list)
                    setRbdLoading(false)
                })
                .catch((error) => {
                    setRbdLoading(false)
                })
        }
    }
   function getOperator() {
    axios.getOperator(userSesion.id)
        .then((response) => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                operator_name: response.data.operador,
            }));
        })
        .catch((error) => {
            console.error("Error en getOperator:", error);
        });
}

    function handleClick(event) {

        event.preventDefault();
        if (loadingButton) return;
        setLoadingButton(true);

        if (!submitConfirm) {
            setSubmitConfirm(!submitConfirm);
            setLoadingButton(false);
            return;
        }

    
        if (formData.images.length === 0){
            setEvError(formData.images.length === 0 ? true : false);
            evidenceRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            setLoadingButton(false);
            return;
        }

        if (error) {
            autoCompleteRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            setLoadingButton(false);
            return; 
        }
        if (errorRut && formData.checked){
            rutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            setLoadingButton(false);
            return
        }
        setLoading(true);
        try {
            handleDownloadPdf()

        } catch (error) {
            console.log("Error ", error)
            setLoading(false);
            setSubmitConfirm(!submitConfirm)
        }

    }
    const handleSwitch = (event) => {
        setFormData({ ...formData, checked: event.target.checked })
    };
    function handleDropDown(value) {
        setFormData({ ...formData, tech_id: value })
    }

    useEffect(() => {
        setPageTitle("Formulario electrónico de Alta del Servicio (FAS)")
        getTechType()
        getOperator()
        setDrawerHidding(false)
        setPageLoaded(true)
    }, []);

    useEffect(() => {
        setFormData({ ...formData, rbd: autoCompleteInput ? autoCompleteInput : "" })
        getRBD()
        getEes()
    }, [autoCompleteInput]);

    if (pageLoaded) {
        return (
            <>
            
           <PageBody>

            

                { loading ? (<HourglassLoader/>) : (<Stack sx={{ background: "#FFFFFF", borderRadius: "0.5rem" }} >
                <Box sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}>
                <Stack  alignItems="left" justifyContent="center" spacing={2}>
                    <Typography
                        variant="h1"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                    >
                        FORMULARIO FAS
                    </Typography>
                    <Box justifyContent="center" sx={{display: 'flex'}}>
                    <Typography
                        variant="small"
                        color="inherit"
                        sx={{ textAlign: "center",  paddingX: "8rem" }}
                    >
                         N° folio:    <Typography variant="small" sx={{color: '#FFF', paddingLeft:'1rem'}}>00000000</Typography>
                    </Typography>
                    </Box>

                            <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  Le presentamos el Formulario de Alta de Servicio, por favor llene todos los campos indicados para un proceso exitoso entre todos los involucrados (operador, EES, SUBTEL y MINEDUC)
                    </Typography>
        </Box>
                    
                  
                    <Typography
                        component="h1"
                        variant="h3"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        DATOS DEL OPERADOR
                    </Typography>
                    <form onSubmit={handleClick} id="fas">
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>

                        <Grid container spacing={2}>
                            <Grid size={6} >
                                <TextField
                                    disabled
                                    id="outlined-required"
                                    label="RUT Operador Instalador"
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={formData.rut}
                                    onChange={(newValue) => setFormData({ ...formData, rut: newValue.target.value })}
                                />
                            </Grid>
                            <Grid size={6}>

                                <TextField
                                    id="outlined-required"
                                    label="Operador"
                                    disabled
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={formData.operator_name}
                                    onChange={(newValue) => setFormData({ ...formData, operator_name: newValue.target.value })}
                                />

                            </Grid>
                        </Grid>
                    </FormControl>

                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                        DATOS DEL EES
                    </Typography>

                    <Box ref={autoCompleteRef}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <Autocomplete
                            key="rbd"
                            value={autoCompleteInput}
                            onChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            inputValue={autoCompleteInput}
                            onInputChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            disablePortal
                            loading={rbdLoading}
                            loadingText="Cargando..."
                            multiple={false}
                            options={RBDList}
                            //blurOnSelect
                            autoComplete
                            size='small'
                            //onBlur={getEes}
                            renderInput=
                            {
                                (params) => {
                                    return (
                                        <TextField required
                                            //onChange={(event) => handleAutoRBDchange(event, 1)}
                                            error={error} 
                                            helperText={errorMessage}
                                            {...params} label="RBD" />
                                    )
                                }
                            }
                        />
                    </FormControl>
                    </Box>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={12} >

                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.ees_name}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Responsable del establecimiento"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers[0]}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Responsable del establecimiento 2"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers[1]}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                        </Grid>
                    </FormControl>
                    <Box ref={rutRef}>
                   <InputRut error={errorRut} errorRut={handleErrorChange} checked={formData.checked} rut={formData.ees_temporal_manager} handleSwitch={handleSwitch} handleRut={(newValue) => setFormData({ ...formData, ees_temporal_manager: newValue})} ></InputRut>
                   </Box>
                    <Typography
                      variant="h3"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                        DATOS DE CONEXIÓN
                    </Typography>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={12} >
                                <InputLabel size="small" id="tech_id">Tecnología</InputLabel>
                                <Select
                                    required
                                    value={formData.tech_id}
                                    labelId="tech_id"
                                    defaultValue={0}
                                    label="Tecnología"
                                    size="small"
                                    sx={{ width: "100%" }}
                                    onChange={event => handleDropDown(event.target.value)}
                                >
                                    {techTypes.map((tech, index) => (

                                        <MenuItem key={index + "menu"} value={tech.id_tipo_tecnologia}>{tech.nombre_enlace}</MenuItem>
                                    )
                                    )}

                                </Select>
                            </Grid>
                            <Grid size={6} >
                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. subida total Nacional (Mbps)"
                                value={formData.speed_up}
                                onValueChange={(newValue) => setFormData({ ...formData, speed_up: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />


                            </Grid>
                            <Grid size={6}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={'es'}
                                    localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DateTimePicker
                                        label="Fecha instalación"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={formData.installation_date}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        onChange={(newValue) => setFormData({ ...formData, installation_date: newValue })}
                                        disableFuture
                                    />
                                </LocalizationProvider>

                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={6} >

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. bajada total Nacional (Mbps)"
                                value={formData.speed_down}
                                onValueChange={(newValue) => setFormData({ ...formData, speed_down: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />
                            </Grid>
                            <Grid size={6}>

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. bajada total Internacional (Mbps)"
                                value={formData.int_speed_down}
                                onValueChange={(newValue) => setFormData({ ...formData, int_speed_down: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />
                               

                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                        <Grid size={6} >

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Latencia (ms)"
                                value={formData.latency}
                                onValueChange={(newValue) => setFormData({ ...formData, latency: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />

                            </Grid>
                            <Grid size={6}>
                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Pérdida de paquetes (%)"
                                value={formData.packet_loss}
                                onValueChange={(newValue) => setFormData({ ...formData, packet_loss: newValue.value })}
                                size="small"
                                sx={{ width: "100%" }}

                                  isAllowed={(values) => {
                                    if (values.value === "") {
                                        return true;
                                    }
                                    const { floatValue } = values;
                                    return floatValue < 100;
                                  }}
                            />

                            </Grid>
                        </Grid>
                    </FormControl>
                 <FormControl sx={{ m: 1, minWidth: "100%" }}>
            <Paper elevation={1} sx={{ width: "100%", borderRadius: "0.5rem" }}>
                <Grid container spacing={2}>
                    <Grid
                        display="flex"
                        justifyContent="flex-start"
                        size={12}
                        sx={{ maxHeight: "small" }}
                    >
                        <Typography variant="body" sx={{ padding: "0.5rem" }}>
                            <Grid container spacing={2}>
                                <Grid
                                    display="flex"
                                    justifyContent="flex-start"
                                    size={12}
                                    sx={{ maxHeight: "small" }}
                                >
                                    <CollectionsOutlinedIcon sx={{ marginRight: "0.5rem" }} />{" "}
                                    {"Subir imágenes de evidencia."}
                                </Grid>
                            </Grid>
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Divider orientation="vertical" flexItem />
                        <LoadingButton
                            component="label"
                            role={undefined}
                            variant="text"
                            tabIndex={-1}
                            startIcon={<FileUploadOutlinedIcon />}
                            sx={{
                                textDecoration: "underline",
                                marginLeft: "1%",
                            }}
                        >
                            Subir imágenes
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                            />
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider orientation="horizontal" flexItem />
                <Grid container spacing={2}>
                    <Grid
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        size={12}
                    >
                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                            <ListItem sx={{ justifyContent: "flex-start" }}>
                                <Typography
                                    align="center"
                                    sx={{ color: evError ? "red" : "#111" }}
                                >
                                    {evError
                                        ? "Debe adjuntar evidencia de la instalación"
                                        : "Lista de imágenes a subir"}
                                </Typography>
                            </ListItem>
                            {formData.images.map((image) => (
                                <ListItem
                                    divider
                                    key={image.name}
                                    display="flex"
                                    alignItems="flex-start"
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="rounded"
                                            alt="preview"
                                            src={URL.createObjectURL(image)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            style: { color: "black" },
                                        }}
                                        primary={image.name}
                                    />
                                    <LoadingButton
                                        size="small"
                                        variant="text"
                                        color="error"
                                        onClick={() => handleRemoveImage(image.name)}
                                    >
                                        Eliminar
                                    </LoadingButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </FormControl>
                
                    </form>
                </Stack>
                </Box>
                <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "2rem" }}>
                    <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                        <Grid ref={evidenceRef} container spacing={2}>
                            {submitConfirm ?
                                <Grid display="flex" justifyContent="center" alignItems="center" size={12} >
                                    <Alert icon={false} variant="filled" severity="warning" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                                        <Typography
                                            variant="body"
                                            color="inherit"
                                            sx={{ textAlign: "center" }}
                                        >
                                            Revise que todos los campos tienen la información correcta antes de enviar el levantamiento de formulario FAS, al clickear “Firmar y enviar” el documento queda firmado por el operador.
                                        </Typography>
                                    </Alert>
                                </Grid>
                                :
                                ""
                            }
                            <Grid display="flex" justifyContent="center" alignItems="center" size={12} >
                                <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
                                  <PageButtonBlack form="fas" disabled={evError} loading={loadingButton}> Firmar y enviar</PageButtonBlack>
                                  </Stack>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Stack>
                </Stack>)
            }
            </PageBody>


            { loadingPdf ?
            (
            <Box sx={{maxWidth: "100%", overflow: "hidden", position: "absolute", width: "1200px", height:"0"}}>
            <Box ref={fasRef}  sx={{padding: "12px"}}>
            <FasHiddenPreview formData={formData} techTypes={techTypes}></FasHiddenPreview>
            </Box>
            </Box>) : null}

      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Ir al listado" onClick={() => navigate("/fas_lista")} onClose={handleClose}></ModalStandard>
            </>

        );
    } else {
        return (
            <Box></Box>
        );
    }


}
