import React, { useEffect, useContext } from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { auth } from "../../hooks/firebase";
import { signInWithCustomToken } from "firebase/auth";
import GeneralContext from "../GeneralContext/GeneralContext";
import ClaveUnicaButton from "../GeneralComponents/ClaveUnicaButton";

import PageButtonBlue from "../GeneralComponents/PageButtonBlue";

import { ReactComponent as ClaveUnica } from "./claveunica.svg";


export default function ClaveUnicaSignIn() {
  const loginUrl = process.env.REACT_APP_CLAVEUNICA_LOGIN_URL;
  const redirectUri = process.env.REACT_APP_CLAVEUNICA_REDIRECT_URI;

  //const loginUrl = "https://sandbox.sagec.subtel.gob.cl/auth/login";
  //const redirectUri = "http://localhost:3000/login";

  const { login, isAuthenticated, setDrawerHidding } =
    useContext(GeneralContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    setDrawerHidding(true);
  }, [setDrawerHidding]);

  useEffect(() => {
    if (!isAuthenticated()) {
      const params = new URLSearchParams(location.search);
      const customToken = params.get("token");
      const customStatus = params.get("status");
      if (customToken && customStatus === "1") {
        signInWithCustomToken(auth, customToken)
          .then((userCredential) => {
            login(userCredential?.user?.accessToken);
          })
          .catch((error) => {
            handleOpen();
            console.error("Error signing in with custom token:", error);
          });
      } else if (customStatus != null) {
        handleOpen();
      }
    }
  }, [location, navigate, isAuthenticated, login]);

  return isAuthenticated() ? (
    <Navigate to="/home" />
  ) : (
    <Container
      maxWidth="xl"
      sx={{
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        padding: "27px 18px !important",
      }}
    >
      <Grid container spacing={0}>
        <Grid item size={6}>
          <Box
            alt="Logo sagec"
            sx={{
              backgroundPosition: "center",
              backgroundImage: "url(/assets/img-autenticacion.jpg)",
              backgroundSize: "cover",
              width: "100%",
              height: "calc(100vh - 54px)",
            }}
          />
        </Grid>
        <Grid
          item
          size={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            display="contents"
            justifyContent="center"
            alignItems="center"
            height="500px"
          >
            <Box
              component="img"
              src="/assets/sagec-logo.svg"
              alt="Line"
              sx={{ width: "225px", height: "86px", marginBottom: "47px" }}
            />
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Roboto",
                fontWeight: "500",
                color: "#111",
                mb: 2,
                lineHeight: "39.837px",
                margin: "0",
                fontSize: "21px",
                letterSpacing: "0.64px",
              }}
            >
              Bienvenido
            </Typography>

            <Typography
              variant="h4"
              sx={{
                fontFamily: "Roboto",
                fontWeight: "700",
                color: "#333",
                fontSize: "50px",
                lineHeight: "66px",
                marginBottom: "5px",
              }}
            >
              Autenticación
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "400px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "1.75rem" }}
              >
                Usted está en la plataforma del Servicio de Administración y Gestión de Conectividad (SAGEC), 
                del proyecto Conectividad para la Educación 2030. Para ingresar use los datos de su Clave Única.

              </Typography>
            </Box>

            <ClaveUnicaButton
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(`${loginUrl}?redirect_uri=${redirectUri}`, "_self")
              }
              startIcon={<ClaveUnica sx={{ marginRigth: "4px" }} />}
            />
          </Box>

          <Box
            component="img"
            src="/assets/logos_footer.jpg"
            alt="Line"
            sx={{
              width: "15%",
              position: "absolute",
              right: "18px",
              bottom: "16px",
              minWidth: "200px",
              maxWidth: "300px",
            }}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        slotProps={{ backdrop: { sx: { opacity: "0.1!important" } } }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "749px",
            height: "261px",
            backgroundSize: "background.paper",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <Box>
              {" "}
              <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
            </Box>
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
              sx={{
                fontSize: "26px",
                lineHeight: "66px",
                margin: "0",
                color: "#333",
              }}
            >
              No existe usuario
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                Su Clave Única no se encuentra registrada en nuestras bases de
                datos, por favor contáctese con su operador o administración
                para coordinar su registro.
              </Typography>
            </Box>

            <PageButtonBlue onClick={handleClose}>
              Volver a login
            </PageButtonBlue>
          </Paper>
        </Box>
      </Modal>
    </Container>
  );
}
