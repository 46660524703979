import React, { useState, useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import "dayjs/locale/es";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from '@mui/material';
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import FusHiddenPreview from "./FusHiddenPreview";


import PageError from "../GeneralComponents/PageError";
import { Radio, RadioGroup, FormControl, FormControlLabel, TextField, List, ListItem, ListItemText, Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { REJECT_F, QUESTIONS } from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function FusPreview() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);


  //const [responseData, setResponseData] = useState(null);
  const [documentVisible, setDocumentVisible] = useState(true);
  const [techTypes, setTechTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    rut: userSesion.id,
    operator_name: "",
    rbd: "",
    estado: "",
    ees_name: "",
    ees_managers: ["", ""],
    ees_temporal_manager_list: [""],
    ees_temporal_manager: "",
    tech_id: "",
    int_speed_down: "",
    installation_date: dayjs(),
    speed_up: "",
    speed_down: "",
    latency: "",
    packet_loss: "",
    evidence: [],
    answers: [],
    rejectOption: null,
  });

  const links = [
    { name: "Inicio", path: "/home" },
    { name: "Listado FUS", path: "/fus_lista" },
    { name: "Firma FUS", path: "/fus_firma/" + id },
  ];;

  function getTechType() {
    axios
      .getTechTypes()
      .then((response) => {
        setTechTypes(response.data)
      })
      .catch((error) => {});
  }
    
  const fetchData = async (query) => {
    setLoading(true);
    axios
      .getFus(id)
      .then((response) => {
        setFormData({
          ...response.data.datos,
          installation_date: dayjs(response.data.datos["installation_date"]),
          estado: response.data.estado,
        });
      })
      .catch((error) => {
        setDocumentVisible();
      })
      .finally(() => {
        setLoading(false);
      });
  };

useEffect(() => {
    getTechType();
    fetchData();
    setDrawerHidding(false);
  }, []);

  return (
    <PageBody>
          <BreadcrumbStandard links={links} />
      {loading ?
      <PageBgBlock pad="0" >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
      </PageBgBlock>
            :

            documentVisible ? 
             (<FusHiddenPreview formData={formData} techTypes={techTypes} id={id}>
                <Stack justifyContent="center" spacing={2}>
                <Box sx={{marginBottom: 2}}>
                  <List
                    sx={{
                      backgroundColor: "#BBDEFB",
                      padding: "1rem 0",
                      borderRadius: "20px",
                      marginTop: "1rem",
                    }}
                  >
                    <ListItem key="header" sx={{ justifyContent: "center" }}>
                      <Typography variant="h1" align="center">
                        ENCUESTA DE SATISFACCIÓN EES
                      </Typography>
                    </ListItem>
                    <Divider sx={{ border: "1px solid white" }} />
                    {QUESTIONS.map((question, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          sx={{ paddingLeft: "9rem", paddingRight: "9rem" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ dispaly: "flex", width: "100%" }}
                          >
                            <Grid size={9}>
                              <ListItemText
                                primary={
                                  <Typography variant="body1">
                                    {question}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid
                              size={3}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <RadioGroup
                                disabled
                                row
                                value={(formData.answers && formData.answers[index]) || ""}
                              >
                                <FormControlLabel
                                  disabled
                                  value="yes"
                                  control={<Radio />}
                                  label="Sí"
                                />
                                <FormControlLabel
                                  disabled
                                  value="no"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {index < QUESTIONS.length - 1 && (
                          <Divider sx={{ border: "1px solid white" }} />
                        )}{" "}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>


              <PageBgBlock pad="2rem 9rem">
                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      CAUSALES DE RECHAZO
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <RadioGroup
                        name="opciones rechazo"
                        value={formData.rejectOption || ""}
                      >
                        {REJECT_F.map((reject, index) => (
                          <FormControlLabel
                            disabled
                            key={index}
                            value={reject}
                            label={reject}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        disabled
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel || ""}
                      />
                    </FormControl>
                  </PageBgBlock>
                  </Stack>
             </FusHiddenPreview>) : (
            <PageError>
                  El documento al que intenta acceder no esta diponible.
                </PageError>
          )
             
        }

    </PageBody>
  );
}
