import React from 'react';
import { Box, Typography } from '@mui/material';

const CustomTextField = ({ label, value, minHeight = '7rem' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        padding: '8px 12px',
        minHeight: minHeight,
      }}
    >
      <Typography
        variant="caption"
        component="label"
        sx={{
          position: 'absolute',
          top: 5,
          left: 12,
          fontSize: 12,
          color: 'text.secondary',
          backgroundColor: 'white',
          px: 0.5,
        }}
      >
        {label}
      </Typography>
      <Box
        component="span"
        sx={{
          mt: 0,
          fontSize: 16,
          color: 'text.primary',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        {value}
      </Box>
    </Box>
  );
};

export default CustomTextField;
