import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Alert from '@mui/material/Alert';
import ApelacionHiddenPreview from "./ApelacionHiddenPreview";

import ModalStandard from "../GeneralComponents/ModalStandard";
import InputFile from "../GeneralComponents/InputFile";
import HourglassLoader from "../GeneralComponents/HourglassLoader";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {PERIODS} from "../../Constants/Constants";


import InputFake from "../GeneralComponents/InputFake";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function Apelacion() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const rutRef = useRef(null);    
  const [evError, setEvError] = useState(false); 
  const [evidence, setEvidence] = useState(null);
  const [documentVisible, setDocumentVisible] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
    const evidenceRef = useRef(null); 


  const [dataTable, setDataTable] = useState([]);
  const [rows, setRows] = useState([{periodo: 1}]);
  const [rowsPrev, setRowsPrev] = useState([]);
  const [operadorId, setOperadorId] = useState(0);
  const apelacionRef = useRef();
  const { periodo } = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState({
    id_operador: "",
    operator_name: "",
    zona: "",
    id_zona: "",
    periodo: "",
    motivo: "",
    evidence: [],
  });

  const PathSegment = () => {
    const location = useLocation();
    const segments = location.pathname.split('/').filter(Boolean);
    let path = segments.length > 1 ? segments[segments.length - 2] : null;
    path = path.startsWith("apelacion") ? "apelacion" : "devengo";
    return path;
  };

const type = PathSegment();

  const setFile = (archivo) => {
    setEvError(!archivo ? true : false);
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
    console.log(archivo);
  };


function dataToList(data) {
    const resultado = [];
      const periodo = data.periodo;
      data.zonas.forEach(zona => {
          const nombreZona = zona.nombre_zona;
          zona.etapas.forEach(etapa => {
              const nombreEtapa = etapa.nombre_etapa;
              etapa.bloques.forEach(bloque => {
                if(bloque.rbd_diferencias_list.length > 0){
                  resultado.push({
                      periodo: periodo,
                      nombre_zona: nombreZona,
                      nombre_etapa: nombreEtapa,
                      nombre_bloque: bloque.nombre_bloque,
                      descripcion:  bloque.rbd_diferencias_list.join(", ")
                  });
                }
                  
              });
          });
      });
    return resultado;
}



async function getOperator() {
  try {
    const response = await axios.getOperator(userSesion.rut);
    const operadorId = response.data.id_operador;
    return 2;
  } catch (error) {
    console.error("Error al obtener el operador:", error);
    throw error;
  }
}


const fetchData = async () => {
  setLoading(true);

  try {
    const operador_id = await getOperator();
    const position = PERIODS.indexOf(periodo);

    if (position === -1) {
      console.error("El período no es válido.");
      return;
    }

    const groupedData = {};
    let errorCount = 0; 
    const processResponse = (response, period) => {
      if (response.data.length > 0) {
        groupedData[period] = dataToList(response.data[0]); 
        console.log(response.data[0])
        setFormData((prevFormData) => ({
        ...prevFormData,
        operador_id : response.data[0].id_operador,
        id_operador: response.data[0].id_operador+'',
        nombre_operador: response.data[0].nombre_operador,
        id: response.data[0].id_prefactura,
        id_prefactura: response.data[0].id_prefactura,
      }));
      } else {
        groupedData[period] = []; 
      }
    };

    try {
      const response_1 = await axios.getPrefacturaByOperador(operador_id, PERIODS[position]);
      processResponse(response_1, PERIODS[position]);
    } catch (error) {
      groupedData[PERIODS[position]] = []; 
      errorCount++;
    }

    if (position != PERIODS.length - 1) {
      try {
        const response_2 = await axios.getPrefacturaByOperador(operador_id, PERIODS[position + 1]);
        processResponse(response_2, PERIODS[position + 1]);
      } catch (error) {
        groupedData[PERIODS[position + 1]] = []; 
        errorCount++;
      }
    } 


    if (errorCount === 2) {
      return;
    }
    if (Object.keys(groupedData).length > 0) {
      const currentData = groupedData[PERIODS[position]] || {};
      console.log(groupedData);
      console.log(currentData);

      setDataTable(groupedData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        periodo: PERIODS[position],
        formulario_datos: groupedData,
      }));
    } 
  } catch (error) {
    console.error("Error al obtener los datos:", error);
    setDocumentVisible(false);
  } finally {
    setLoading(false);
  }
};






const handleDeleteRow = (period, index) => {
  setDataTable((prevData) => {
    const newPeriodData = [...prevData[period]];
    newPeriodData.splice(index, 1);
    const updatedData = { ...prevData, [period]: newPeriodData };
    setFormData((prevFormData) => ({
      ...prevFormData,
      formulario_datos: updatedData,
    }));

    return updatedData;
  });
};

const handleChangeDescription = (period, index, value) => {
  setDataTable((prevData) => {
    const newPeriodData = [...prevData[period]];
    newPeriodData[index].descripcion = value;
    const updatedData = { ...prevData, [period]: newPeriodData };

    setFormData((prevFormData) => ({
      ...prevFormData,
      formulario_datos: updatedData,
    }));

    return updatedData;   });
};


const handleDeleteCurrent = (index) => {
  const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
  setRowsPrev(newRows);
};

  const handleChangeCurrent = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].descripcion = value;
    setRows(updatedRows);
  };

const handleDeletePrev = (index) => {
  const newRows = rowsPrev.filter((_, rowIndex) => rowIndex !== index);
  setRowsPrev(newRows);
};

  const handleChangePrev = (index, value) => {
    const updatedRows = [...rowsPrev];
    updatedRows[index].descripcion = value;
    setRowsPrev(updatedRows);
  };



  const save = async (event) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    if (!evidence){
        setEvError(!evidence ? true : false);
        evidenceRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        setLoading(false);
        return;
    }

    if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        setLoading(false);
        return;
    }
    handleDownloadPdf()
  };

const handleDownloadPdf = () => {

    setLoadingPdf(true);
    setTimeout(() => {
    const element = apelacionRef.current;
    
    html2canvas(element, { scale: 2 })
      .then((canvas) => {
            //const imageData = canvas.toDataURL('image/png');
            const imageData = canvas.toDataURL('image/jpeg', 1);
           
            const pdfWidth = 210;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 7]);

     
            pdf.setFillColor(255, 255, 255);

            pdf.rect(0, 0, pdfWidth, pdfHeight , 'F');
            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            
            const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                files: evidence && evidence.archivo ? [evidence.archivo] : [],
            }

            form.append("data", JSON.stringify(localFormData));
            
            for (let i = 0; i < localFormData["files"].length; i++) {
                form.append("evidence", localFormData["files"][i]);
            }
            
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesApelacion(form, type)
                .then((response) => {
                    setLoading(false);
                    setSubmitConfirm(!submitConfirm)
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        setLoadingPdf(false);
        setSubmitConfirm(false);
      })
      .catch((error) => {
        console.error('Error generando el PDF:', error);
        setLoadingPdf(false);
        setLoading(false);
        setSubmitConfirm(false);
      });
  }, 0);

};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

      useEffect(() => {
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);


      useEffect(() => {
        fetchData();
        setDrawerHidding(false)
    }, []);

  return (
    <>
    <PageBody>
      <PageBgBlock pad="0" >
      { loading ? (<HourglassLoader/>) : (<Box><Box sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN {type === "apelacion" ? " PRE-FACTURA" : "DEVENGO"}
        </Typography>
        <Stack alignItems="left" justifyContent="center" spacing={2} >
        <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
        <Typography
            variant="small"
            color="inherit"
            sx={{ textAlign: "center", justifyContent: "center", paddingY: "0", marginTop: '0'}}
        >
             N° folio: <Typography variant="small" sx={{paddingLeft:'1rem', marginTop: '0', color: '#FFF'}}>{String('').padStart(8, '0')}</Typography>
        </Typography>
        </Box>

        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su {type === "apelacion" ? " pre-factura" : "devengo"} proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, <b>si este documento es aceptado por SUBTEL la diferencia en {type === "apelacion" ? " las pre-factura" : "los devengos"} quedará corregida.</b>
          </Typography>
        </Box>
        </Stack>

        <form onSubmit={save} id="apelacion">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre_operador"
                    label="Nombre Operador"
                    size="small"
                    value={formData.nombre_operador}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            

            
          {Object.keys(dataTable).length === 0 ? (
        <Alert variant="outlined" severity="error" sx={{width: "100%", padding: "1rem 2rem"}}>
                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography variant="body" align="left" >
                    No existen elementos con diferencias para apelar.
                  </Typography>
                </Box>
              </Alert>
      ) : (
        Object.keys(dataTable).map((period, index) => (
          <div key={period} style={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{marginBottom: "2rem"}}
            >
              PERIODO {index === 0 ? "ACTUAL" : "ANTERIOR"}: {period}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                              setDataTable((prevData) => {
                                const newData = { ...prevData };
                                delete newData[period];
                                
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  formulario_datos: newData,
                                }));
                                
                                return newData;
                              });
                            }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>Periodo</TableCell>
                    <TableCell>Zona</TableCell>
                    <TableCell>Etapa</TableCell>
                    <TableCell>Bloque</TableCell>
                    <TableCell>Descripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable[period].map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDeleteRow(period, index)}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.periodo}</TableCell>
                      <TableCell>{row.nombre_zona}</TableCell>
                      <TableCell>{row.nombre_etapa}</TableCell>
                      <TableCell>{row.nombre_bloque}</TableCell>
                      <TableCell sx={{p: 0}}>
                        <TextField
                          required
                          multiline
                          minRows={2}
                          maxRows={4}
                          size="small"
                          sx={{ width: "100%" }}
                          value={row.descripcion}
                          onChange={(e) =>
                            handleChangeDescription(
                              period,
                              index,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      )}
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Escriba aquí la justificación"
                size="small"
                value={formData.motivo}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    motivo: event.target.value,
                  })
                }
              />
            </FormControl>

            <Box ref={evidenceRef}>
            <InputFile onFileSelect={setFile} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
            <Typography
              variant="small" align="left" sx={{ marginBottom: "18px", color:  evError ? 'red' : '#111' }}
            >
             {evError ? "Debe adjuntar evidencia" : "Máximo 25mb"}
            </Typography>
            </Box>
          </Stack>

        </form>
          </Box>
          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
          <Grid>
            {submitConfirm ?
                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}} >
                    <Alert icon={false} variant="filled" severity="warning" display="flex"  sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                        <Typography
                            variant="body"
                            color="inherit"
                            sx={{ textAlign: "center" }}
                        >
                            Revise que todos los campos contienen la información correcta antes de firmar la Apelación. Al hacer clic en 'Firmar y enviar', el documento quedará firmado sin opción de modificación.
                        </Typography>
                    </Alert>
                </Grid>
                :
                ""
            }
          </Grid>

          <PageButtonBlack form="apelacion" loading={loading} disabled={Object.keys(dataTable).length === 0}> Firmar y enviar</PageButtonBlack>
          </Stack>

          </Box>)}
      </PageBgBlock>
      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/apelaciones_lista")} onClose={handleClose}></ModalStandard>
    </PageBody>



      { loadingPdf ?
            (
            <Box sx={{maxWidth: "100%", overflow: "hidden", position: "absolute", width: "1200px", height: 0}}>
            <Box ref={apelacionRef}  sx={{padding: "12px"}}>
            <ApelacionHiddenPreview formData={formData} evidence={evidence}></ApelacionHiddenPreview>
            </Box>
            </Box>) : null}
    </>
  );
}
