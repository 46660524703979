import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import InputFake from "../GeneralComponents/InputFake";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";
import SignatureFooter from "../GeneralComponents/SignatureFooter";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Alert from '@mui/material/Alert';
import { Button, InputAdornment } from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import FileDownload from "@mui/icons-material/FileDownload";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);



export default function ApelacionHiddenPreview({formData, type, id = null, evidence= null,}) {
  return (
    <>
    <PageBgBlock pad={id ? "0" : "2rem 9rem"}>
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN {type === "apelacion" ? " PRE-FACTURA" : "DEVENGO"}
        </Typography>

        <Stack alignItems="left" justifyContent="center" spacing={2} >
        <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
        <Typography
            variant="small"
            color="inherit"
            sx={{ textAlign: "center", justifyContent: "center", paddingY: "0", marginTop: '0'}}
        >
             N° folio: <Typography variant="small" sx={{paddingLeft:'1rem', marginTop: '0', color: id ? '' :'#FFF'}}>{String(id || '').padStart(8, '0')}</Typography>
        </Typography>
        </Box>

        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su {type === "apelacion" ? " pre-factura" : "devengo"} proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, <b>si este documento es aceptado por SUBTEL la diferencia en {type === "apelacion" ? " las pre-factura" : "los devengos"} quedará corregida.</b>
          </Typography>
        </Box>
        </Stack>

        <form id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>

<Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.nombre_operador}
                  />
                </FormControl>
              </Grid>
            </Grid>

            
          {formData.formulario_datos && Object.keys(formData.formulario_datos).length === 0 ? (
        <Alert variant="outlined" severity="error" sx={{width: "100%", padding: "1rem 2rem"}}>
                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography variant="body" align="left" >
                    No existen elementos con diferencias para apelar.
                  </Typography>
                </Box>
              </Alert>
      ) : (
        formData.formulario_datos && 
Object.keys(formData.formulario_datos)
  .sort((a, b) => b.localeCompare(a)).map((period, index) => (
          <div key={period} style={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{marginBottom: "2rem"}}
            >
              PERIODO {index === 0 ? "ACTUAL" : "ANTERIOR"}: {period}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>Periodo</TableCell>
                    <TableCell>Zona</TableCell>
                    <TableCell>Etapa</TableCell>
                    <TableCell>Bloque</TableCell>
                    <TableCell>RBDs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.formulario_datos[period].map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <IconButton>
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.periodo}</TableCell>
                      <TableCell>{row.nombre_zona}</TableCell>
                      <TableCell>{row.nombre_etapa}</TableCell>
                      <TableCell>{row.nombre_bloque}</TableCell>
                      <TableCell sx={{paddingX: 1, paddingY: 0, width: "300px", maxWidth: "300px"}}>
                        <InputFake value={row.descripcion} minHeight="4rem" ></InputFake>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      )}
          
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>



              <InputFake value={formData.motivo} ></InputFake>


              


           
            <Box >
                        {evidence ? (
              <Box>
                <InputFile
                  onFileSelect={() => {}}
                  initialFile={evidence}
                  fileType="pdf"
                  placeholder="Subir evidencia (formato PDF)"
                />
                <Typography
                  variant="small"
                  align="left"
                  sx={{ marginBottom: "18px", color: "#111" }}
                >
                  Máximo 25mb
                </Typography>
              </Box>
            ) : (
              <>
                
                <Grid container spacing={2}>
                  <Grid size={8}>
                    <TextField
                      disabled
                      size="small"
                      variant="outlined"
                      placeholder="Subir evidencia (formato PDF)"
                      value={formData.evidence}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginLeft: "10px" }}
                          >
                            <ImageSearchIcon sx={{ color: "black" }} />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      sx={{ width: "100%", maxWidth: "100%" }}
                      readOnly
                    />
                  </Grid>
                  <Grid size={4}>
                    <Button
                      fullWidth
                      variant="text"
                      disabled={
                        formData.evidence && formData.evidence.length > 0
                          ? false
                          : true
                      }
                      color="primary"
                      onClick={() =>
                        window.open(formData.evidence[0], "_blank")
                      }
                      startIcon={<FileDownload />}
                    >
                      Descargar evidencia
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            </Box>
          </Stack>
          
        </form>
      </PageBgBlock>
      {!id ? <SignatureFooter ees={false}></SignatureFooter> : null}
          

        

            </>
  );
}
