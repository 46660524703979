import React, { useState, useEffect, useRef  } from 'react';
import GeneralContext from './GeneralContext';
import { useCookies } from 'react-cookie';
import axios from '../../api/axios';
import { auth } from '../../hooks/firebase';

const ContextProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("");
  const [drawerHidding, setDrawerHidding] = useState(true);
  const [userSesion, setUserSesion] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['sagec_access_token']);
  const [userResources, setUserResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const refreshInterval = useRef(null);


  const login = (token) => {
    setCookie('sagec_access_token', token, {
      path: '/',
      maxAge: 3600,
      secure: true,
      sameSite: 'None',
    });

  };

  const logout = () => {
    console.log("here");
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
      refreshInterval.current = null;
    }
    removeCookie('sagec_access_token', { path: '/' });
    setUserSesion(null);
    window.location.href ='https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=/'

  };

  const getToken = () => {
    return cookies.sagec_access_token;
  };
 

  const isAuthenticated = () => {
    return !!cookies.sagec_access_token;
  };

  const includeRol = (roles) => {
    const rolesArray = Array.isArray(roles) ? roles : [roles];
    return (userSesion && userSesion.roles && rolesArray.some(element => userSesion.roles.map(role => role.role_id).includes(element)));

  };

  const getUser = async () => {
    axios.getUserLogin(cookies.sagec_access_token)
    .then((response)=>{
      //response.data.roles[0].recursos.push({recurso_id: 40, nombre_recurso: '/prefactura_detalle', descripcion: 'Crear Apelaciones', permisos: 'escritura'});

      const combinedResources = response.data.roles.flatMap(role => role.recursos.map(resource => resource.nombre_recurso));


      

      setUserSesion(response.data);
      console.log(combinedResources);
      setUserResources(combinedResources);

    })
    .catch((error) => {
      logout();
    }).finally((error) => {
      setLoading(false);
    })
  };

   const refresh = async () => {
    try {
      console.log("refresh");
      const user = auth.currentUser;
      console.log(user && cookies.sagec_access_token);
      if (user && cookies.sagec_access_token) {
        const newToken = await user.getIdToken(true);
        console.log(newToken);
        if (cookies.sagec_access_token) {
          setCookie('sagec_access_token', newToken, {
            path: '/',
            maxAge: 3600,
            secure: false,
            sameSite: 'None',
          });
        }
        
        return newToken;
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error al refrescar el token con Firebase:", error);
      logout();
    }
  };

  useEffect(() => {
    console.log(cookies.sagec_access_token);
    if (cookies.sagec_access_token) {
        getUser();
    }

    if (cookies.sagec_access_token && !refreshInterval.current) {
      getUser();
      refreshInterval.current = setInterval(() => {
        refresh();
      }, 600000); 
    }

    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
        refreshInterval.current = null;
      }
    };
  }, [cookies.sagec_access_token]);

  return (
    <GeneralContext.Provider value=
      {{
        pageTitle,
        setPageTitle,
        drawerHidding,
        setDrawerHidding,
        userSesion,
        setUserSesion,
        login,
        logout,
        getToken,
        isAuthenticated,
        includeRol,
        userResources, 
        loading
      }}>
      {children}
    </GeneralContext.Provider>
  );
};

export default ContextProvider;