import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Divider,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Stack,
} from "@mui/material";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import TuneIcon from "@mui/icons-material/Tune";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

export default function ParqueTech() {
  const { pageTitle, setPageTitle } = useContext(GeneralContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [RBDList, setRBDList] = useState([]);
  const [rbdLoading, setRbdLoading] = useState(false);
  const [techTypes, setTechTypes] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [zonaData, setZonaData] = useState({});
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const [autoCompleteInputZonas, setAutoCompleteInputZonas] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [chipList, setChipList] = useState([]);
  const [formData, setFormData] = useState({ tech_id: "" });

  const [emptyZona, setEmptyZona] = useState(false);
  const [emptyRBD, setEmptyRBD] = useState(false);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteChip = (chipToDelete) => {
    setChipList(chipList.filter((chip) => chip !== chipToDelete));
  };

  function getRBD() {
    if (autoCompleteInput !== "") {
      setRbdLoading(true);
      axios
        .getRBD(autoCompleteInput)
        .then((response) => {
          setRBDList(response.data.rbd_list);
          setRbdLoading(false);
          setError(false);
          setErrorMsg("");
        })
        .catch(() => {
          setRBDList([]);
          setRbdLoading(false);
          setError(true);
          setErrorMsg("Colegio no encontrado.");
        });
    }
  }

  function getTechType() {
    axios
      .getTechTypes()
      .then((response) => {
        setTechTypes(response.data);
      })
      .catch((error) => {});
  }

  function getAllZones() {
    axios
      .getZonas()
      .then((response) => {
        const zonasLista = response.data.zona_list.map((zona) => zona.id_zona);
        setZonas(response.data.zona_list);
      })
      .catch((error) => {
      });
  }

  function handleDropDown(value) {
    setFormData({ ...formData, tech_id: value });
  }
  useEffect(() => {
    getTechType();
    getAllZones();
  }, []);

  useEffect(() => {
    if (autoCompleteInput) {
      getRBD();
    }
  }, [autoCompleteInput]);

  const handleAddRow = () => {
    if (!autoCompleteInput) {
      setError(true);
      setErrorMsg("Selecciona un RBD antes de agregar.");
      return;
    }

    if (chipList.includes(autoCompleteInput)) {
      setError(true);
      setErrorMsg("El RBD ya está en la lista.");
      return;
    }

    setChipList([...chipList, autoCompleteInput]);
    setAutoCompleteInput("");
    setError(false);
    setErrorMsg("");
    setEmptyRBD(true);
  };

  const handleAddRowZonas = () => {

    const alreadyExists = rows.some(
      (row) => row.id_zona === zonaData.id_zona
    );
    if (alreadyExists) {
      return;
    }

    setRows((prevRows) => [
      ...prevRows,
      {
        rbd: zonaData.rbd || "",
        nombre_operador: zonaData.nombre_operador || "",
        tipo_tecnologia: zonaData.tipo_tecnologia || "",
        id_zona: zonaData.id_zona,
      },
    ]);

    setAutoCompleteInputZonas("");
    setZonaData({});
    setError(false);
    setEmptyZona(false);
  };

const handleDeleteRow = (index) => {
  setRows((prevRows) => {
    const updatedRows = prevRows.filter((_, i) => i !== index);
    return updatedRows;
  });
};

    const handleSendZonas = (event) => {
      event.preventDefault();
      console.log("handleSendZonas");
    if (rows.length === 0) {
      setEmptyZona(true);
      return;
    }
    
 
      const updateEesState = async () => {
      setLoading(true); 
      const updatedRows = await Promise.all(
        rows.map(async (row) => {
          try {
            await axios.updateTechByZone(row.id_zona, formData.tech_id); 
            return { ...row, procesoExitoso: true }; 
          } catch (error) {
            return { ...row, procesoExitoso: false }; 
          }
        })
      );
      setRows([]);
      setLoading(false); 
    };
    updateEesState();
    getAllZones();
    
  };


      const handleSendRBDs = (event) => {
      console.log("handleSendRBDs");
      console.log(value);
        event.preventDefault();
    if (chipList.length === 0) {
      setEmptyRBD(true);
      return;
    }
    
 
      const updateEesState = async () => {
      setLoading(true); 
      const updatedRows = await Promise.all(
        chipList.map(async (row) => {
          try {
            await axios.updateTechByRBD(row, formData.tech_id); 
            return { ...row }; 
          } catch (error) {
            return { ...row }; 
          }
        })
      );
      setLoading(false); 
      setChipList([])
    };
    updateEesState();
    
  };

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
      <PageBgBlock>
          <form onSubmit={value === "1" ? handleSendZonas : handleSendRBDs} id="parque_tech">
          
        <Stack alignItems="left" justifyContent="center" spacing={2}>
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
          >
            CAMBIO DE TECNOLOGÍA
          </Typography>
          <Box
            justifyContent="center"
            sx={{
              maxWidth: "100%",
              margin: "0 auto",
              display: "flex",
            }}
          >
            <Typography
              variant="body"
              align="center"
              sx={{ marginBottom: "3rem", maxWidth: "45rem" }}
            >
              Para agregar fechas relacionadas con inicio de servicio o
              recepción de obras llene los campos, cuide que la información esté
              correcta antes de agregar.
            </Typography>
          </Box>
        </Stack>

        <Typography variant="h3" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          SELECCIONE NUEVA TECNOLOGÍA
        </Typography>
        <FormControl sx={{ marginY: 2, width: "100%", spacing: 2 }}>
          <Grid container spacing={2}>
            <Grid size={12}>
              <InputLabel size="small" id="tech_id">
                Tecnología
              </InputLabel>
              <Select
                required
                value={formData.tech_id}
                labelId="tech_id"
                defaultValue={0}
                label="Tecnología"
                size="small"
                sx={{ width: "100%" }}
                onChange={(event) => handleDropDown(event.target.value)}
              >
                {techTypes.map((tech, index) => (
                  <MenuItem
                    key={index + "menu"}
                    value={tech.id_tipo_tecnologia}
                  >
                    {tech.nombre_enlace}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </FormControl>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
              >
                <Tab label="Por zona" value="1" />
                <Tab label="Por RBD" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={2}>
                <Grid size={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <Autocomplete
        value={autoCompleteInputZonas || null}
        onChange={(event, newValue) => {
          setAutoCompleteInputZonas(newValue || ""); 
          if (newValue) {
            setZonaData(newValue); 
          }
        }}
        inputValue={autoCompleteInput}
        onInputChange={(event, newInputValue) => 
          setAutoCompleteInput(newInputValue || "")
        }
        disablePortal
        loading={rbdLoading}
        loadingText="Cargando..."
        options={zonas}
        getOptionLabel={(option) => option?.id_zona || ""}
        isOptionEqualToValue={(option, value) =>
          option?.nombre_zona === value?.id_zona
        }
        autoComplete
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={errorMsg}
            label="Zona"
            placeholder="Buscar zona"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  <TuneIcon />
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
                </Grid>
                <Grid size={3}>
                  <PageButtonSimple
                    pad=".5rem"
                    width="auto"
                    onClick={handleAddRowZonas}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    disabled={!autoCompleteInputZonas || rbdLoading}
                  >
                    Agregar a la lista
                  </PageButtonSimple>
                </Grid>
              </Grid>

              <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell sx={{width: "80px"}}>
                      <IconButton
                        onClick={() => {
                              setRows([]);
                            }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                      <TableCell>Id Zona</TableCell>
                      <TableCell>Operador</TableCell>
                      <TableCell>Tecnología</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.map((row, index) => (

                      <TableRow key={index}>
                    <TableCell>
                        <IconButton
                          onClick={() => handleDeleteRow(index)}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                        <TableCell sx={{ verticalAlign: "middle" }}>
                          {row.id_zona}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "middle" }}>
                          {row.nombre_operador}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "middle" }}>
                          {row.tipo_tecnologia}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows.length === 0 && emptyZona ? (
                <Typography
                  variant="small"
                  color="error"
                  sx={{ flexGrow: 1 }}
                >
                  No hay elementos en la tabla.
                </Typography>
                ) : null}

              <Box sx={{ margin: "2rem auto 0" }}>
                <PageButtonSimple
                  width="30rem"
                  disabled={rows.length === 0}
                  form="parque_tech"
                  startIcon={<ModeEditOutlineOutlinedIcon />}
                  loading={loading}
                >
                  Modificar tecnología
                </PageButtonSimple>
              </Box>
            </TabPanel>

            <TabPanel value="2">
              <Grid container spacing={2}>
                <Grid size={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <Autocomplete
                      value={autoCompleteInput}
                      onChange={(event, newValue) => {
                        setAutoCompleteInput(newValue || "")
                      }}
                      inputValue={autoCompleteInput}
                      onInputChange={(event, newValue) =>
                        setAutoCompleteInput(newValue || "")
                      }
                      disablePortal
                      loading={rbdLoading}
                      loadingText="Cargando..."
                      options={RBDList}
                      autoComplete
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          error={error}
                          helperText={errorMsg}
                          {...params}
                          label="RBD"
                          placeholder="Buscar RBD"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                {params.InputProps.startAdornment}
                                <TuneIcon />
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid size={3}>
                  <PageButtonSimple
                    pad=".5rem"
                    width="auto"
                    onClick={handleAddRow}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    disabled={!autoCompleteInput || rbdLoading}
                  >
                    Agregar a la lista
                  </PageButtonSimple>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Lista de RBDs"
                  sx={{ marginTop: 4 }}
                  InputProps={{
                    startAdornment: (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {chipList.map((chip) => (
                          <Chip
                            key={chip}
                            label={chip}
                            onDelete={() => handleDeleteChip(chip)}
                            deleteIcon={<RemoveCircleOutlineOutlinedIcon />}
                            sx={{
                              backgroundColor: "rgba(61, 108, 174, 0.20)",
                              "& .MuiChip-deleteIcon": {
                                color: "rgba(61, 108, 174, 0.80)",
                              },
                              "&:hover .MuiChip-deleteIcon": {
                                color: "rgba(61, 108, 174, 1)",
                              },
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                />
                {chipList.length === 0 && emptyRBD ? (
                <Typography
                  variant="small"
                  color="error"
                  sx={{ flexGrow: 1 }}
                >
                  No hay elementos en la lista.
                </Typography>
                ) : null}
              </Grid>
              

              <Box sx={{ margin: "2rem auto 0" }}>
                <PageButtonSimple
                  width="30rem"
                  disabled={chipList.length === 0}
                  form="parque_tech"
                  startIcon={<ModeEditOutlineOutlinedIcon />}
                  loading={loading}
                >
                  Modificar tecnología
                </PageButtonSimple>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
          </form>
      </PageBgBlock>
    </>
  );
}
