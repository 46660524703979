import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputFake from "../GeneralComponents/InputFake";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";
import SignatureFooter from "../GeneralComponents/SignatureFooter";
import FileDownload from "@mui/icons-material/FileDownload";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { Button, InputAdornment } from "@mui/material";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import {
  Divider,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Switch,
  OutlinedInput,
} from "@mui/material";
import { DAYS, REJECTED } from "../../Constants/Constants";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasHiddenPreview({
  formData,
  evidence = null,
  children = null,
  id = null,
}) {
  return (
    <>
    <PageBgBlock pad={id ? "0" : "2rem 9rem"}>
      <Box>
        <Typography
                variant="h1"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
              >
          LEVANTAMIENTO DE CONTINGENCIA
        </Typography>
        
      <Stack alignItems="left" justifyContent="center" spacing={2} >
        <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
        <Typography
            variant="small"
            color="inherit"
            sx={{ textAlign: "center", justifyContent: "center", paddingY: "0", marginTop: '0'}}
        >
             N° folio: <Typography variant="small" sx={{paddingLeft:'1rem', marginTop: '0', color: id ? '' :'#FFF'}}>{String(id || '').padStart(8, '0')}</Typography>
        </Typography>
        </Box>

        <Box
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
            Complete el siguiente formulario para registrar una contingencia
            (Ticket Mesa de Ayuda). Asegúrese de proporcionar todos los datos
            requeridos para facilitar el proceso de asistencia y resolución.
            Nuestro equipo revisará su solicitud y le brindará el soporte
            necesario a la brevedad.
          </Typography>
        </Box>
        </Stack>

        <form id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL EMISOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="rut"
                    label="Rut Operador Administrador"
                    size="small"
                    value={formData.rut_emisor}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre"
                    label="Nombre y Apellido"
                    size="small"
                    value={formData.name_emisor}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS EES
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ minWidth: "100%" }}>
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <TextField
                        disabled
                        id="outlined-required"
                        label="RBD"
                        size="small"
                        sx={{ width: "100%" }}
                        value={formData.rbd}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    label="ID Zona"
                    size="small"
                    disabled
                    value={formData.id_zona}
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>

              <Grid size={12}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id="outlined"
                    label="Nombre del Colegio"
                    size="small"
                    disabled
                    value={formData.nombre_colegio}
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box>
              <InputRut
                errorRut={() => {}}
                handleRut={() => {}}
                margin="0"
                checked={formData.checked}
                rut={formData.ees_temporal_manager}
              ></InputRut>
            </Box>

            <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
              <Grid
                container
                sx={{
                  width: "100%",
                  background: formData.contingencia_programada
                    ? "#BBDEFB"
                    : "#E4E4E4",
                  borderRadius: "0.5rem",
                  padding: "2%",
                }}
                spacing={2}
              >
                <Grid sx={{ width: "100%" }} size={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={formData.contingencia_programada}
                      />
                    }
                    label="CONTINGENCIA PROGRAMADA"
                  />
                </Grid>
                <Grid size={12}>
                  <InputFake value={formData.texto_contingencia_pgr}></InputFake>
                </Grid>
                <Grid size={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                  >
                    <DateTimePicker
                      disabled
                      sx={{ width: "100%" }}
                      label="Fecha y hora de inicio"
                      value={formData.fecha_hora_inicio}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid size={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                  >
                    <DateTimePicker
                      disabled
                      sx={{ width: "100%" }}
                      label="Fecha y hora de término"
                      value={formData.fecha_hora_termino}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid size={6}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="days">Días</InputLabel>
                    <Select
                      disabled
                      id="days"
                      multiple
                      value={formData.days || []}
                      input={<OutlinedInput label="Días" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {DAYS.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={
                              formData.days && formData.days.includes(name)
                            }
                          />
                          <ListItemText
                            primary={
                              <Typography sx={{ color: "black" }}>
                                {name}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid size={6}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="repetir-label">Repetir</InputLabel>
                    <Select
                      disabled
                      labelId="repetir-label"
                      id="repetir"
                      value={formData.repetir}
                      label="Repetir"
                      size="small"
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={"Diaria"}>Diariamente</MenuItem>
                      <MenuItem value={"Semanal"}>Semanalmente</MenuItem>
                      <MenuItem value={"Mensual"}>Mensualemnte</MenuItem>
                      <MenuItem value={"Anual"}>Anualmente</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>

            <Typography variant="h3" color="inherit" noWrap sx={{}}>
              DATOS DE FALLA
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    disabled
                    sx={{ width: "100%" }}
                    label="Fecha y hora ocurrencia"
                    value={formData.fecha_hora_ocurrencia}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                    disableFuture
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    disabled
                    sx={{ width: "100%" }}
                    label="Fecha y hora solución"
                    value={formData.fecha_hora_solucion}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="severidad-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="severidad-label"
                    id="severidad"
                    value={formData.severidad}
                    label="Severidad"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Crítica"}>Crítica</MenuItem>
                    <MenuItem value={"Mayor"}>Mayor</MenuItem>
                    <MenuItem value={"Menor"}>Menor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="codigo_tipo_evento-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Código tipo de evento
                  </InputLabel>
                  <Select
                    disabled
                    required
                    labelId="codigo_tipo_evento-label"
                    id="codigo_tipo_evento"
                    value={formData.codigo_tipo_evento}
                    label="Código tipo de evento"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"1"}>
                      1 - Falla física equipo de datos cliente
                    </MenuItem>
                    <MenuItem value={"2"}>
                      2 - Bloqueo equipo de datos cliente
                    </MenuItem>
                    <MenuItem value={"3"}>
                      3 - Corte enlace físico (Fibra óptica o cobre)
                    </MenuItem>
                    <MenuItem value={"4"}>4 - Corte enlace microondas</MenuItem>
                    <MenuItem value={"5"}>
                      5 - Caída plataforma satelital
                    </MenuItem>
                    <MenuItem value={"6"}>
                      6 - Caída enlace internacional
                    </MenuItem>
                    <MenuItem value={"7"}>7 - Falla nodo de red</MenuItem>
                    <MenuItem value={"8"}>8 - Falla red de transporte</MenuItem>
                    <MenuItem value={"9"}>
                      9 - Error de configuración firewall
                    </MenuItem>
                    <MenuItem value={"10"}>
                      10 - Error de configuración DNS
                    </MenuItem>
                    <MenuItem value={"11"}>
                      11 - Error de configuración otro nodo de servicio
                    </MenuItem>
                    <MenuItem value={"12"}>
                      12 - Falla de equipo firewall
                    </MenuItem>
                    <MenuItem value={"13"}>13 - Falla de equipo DNS</MenuItem>
                    <MenuItem value={"14"}>
                      14 - Falla de equipo otro nodo de servicio
                    </MenuItem>
                    <MenuItem value={"15"}>15 - Corte de energía EES</MenuItem>
                    <MenuItem value={"16"}>
                      16 - Corte suministro público energía
                    </MenuItem>
                    <MenuItem value={"17"}>
                      17 - Otro responsabilidad del operador
                    </MenuItem>
                    <MenuItem value={"18"}>
                      18 - Otro responsabilidad de terceros
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="origen-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Origen de la falla
                  </InputLabel>
                  <Select
                    disabled
                    required
                    labelId="origen-label"
                    id="origen"
                    value={formData.origen}
                    label="Origen de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Falla interna al adjudicatario"}>
                      Falla interna al adjudicatario
                    </MenuItem>
                    <MenuItem value={"Falla externa al adjudicatario"}>
                      Falla externa al adjudicatario
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="extension_falla-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Extensión de la falla
                  </InputLabel>
                  <Select
                    disabled
                    required
                    labelId="extension_falla-label"
                    id="extension_falla"
                    value={formData.extension_falla}
                    label="Extensión de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                    <MenuItem value={"Evento mayor"}>Evento mayor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h3" color="inherit" noWrap sx={{}}>
              DESCRIPCIÓN DE LA FALLA
            </Typography>

            <InputFake value={formData.texto_falla_operador}></InputFake>

            {evidence ? (
              <Box>
                <InputFile
                  onFileSelect={() => {}}
                  initialFile={evidence}
                  fileType="pdf"
                  placeholder="Subir evidencia (formato PDF)"
                />
                <Typography
                  variant="small"
                  align="left"
                  sx={{ marginBottom: "18px", color: "#111" }}
                >
                  Máximo 25mb
                </Typography>
              </Box>
            ) : (
              <>
                {formData.estado === REJECTED ? (
                  <>
                    <Divider sx={{ my: 2 }} />
                    

                    <Typography variant="h3" color="inherit" noWrap sx={{}}>
                      JUSTIFICACIÓN DE SUBTEL
                    </Typography>

                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                      <TextField
                        disabled
                        required
                        multiline
                        minRows={4}
                        maxRows={30}
                        id="outlined-required"
                        placeholder="Texto aquí"
                        size="small"
                        value={formData.justificacion_subtel}
                      />
                    </FormControl>
                  </>
                ) : null}
                <Grid container spacing={2}>
                  <Grid size={8}>
                    <TextField
                      disabled
                      size="small"
                      variant="outlined"
                      placeholder="Subir evidencia (formato PDF)"
                      value={formData.evidence}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginLeft: "10px" }}
                          >
                            <ImageSearchIcon sx={{ color: "black" }} />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      sx={{ width: "100%", maxWidth: "100%" }}
                      readOnly
                    />
                  </Grid>
                  <Grid size={4}>
                    <Button
                      fullWidth
                      variant="text"
                      disabled={
                        formData.evidence && formData.evidence.length > 0
                          ? false
                          : true
                      }
                      color="primary"
                      onClick={() =>
                        window.open(formData.evidence[0], "_blank")
                      }
                      startIcon={<FileDownload />}
                    >
                      Descargar evidencia
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>




        </form>
      </Box>
      </PageBgBlock>
      {children}
      {!id ? <SignatureFooter mineduc={formData.checked}></SignatureFooter> : null}
    </>
  );
}
