import React, { useState, useContext } from "react";
import { 
  Card, CardHeader, Avatar, Chip, Divider, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Button, Typography, Box, Stack
} from '@mui/material';
import axios from "../../api/axios";
import Grid from "@mui/material/Grid2";
import GeneralContext from "../GeneralContext/GeneralContext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from 'react-router-dom';
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import { Download as DownloadIcon } from "@mui/icons-material";

import {ROL_OP_ADMIN, ROL_ADMIN} from "../../Constants/Constants";


const saldoData = [
  { label: "SALDO MENSUAL:", value: "$-" },
  { label: "DIFERENCIAS:", value: "$-" },
  { label: "SALDO A FAVOR PERIODO ANTERIOR POR CONTINGENCIAS:", value: "$-" },
  { label: "SALDO POR DEVENGOS:", value: "$-" },
  { label: "Total:", value: "$-" },
];

export default function PrefacturaOpCard({ data, dataDev, fetch }) {
  const { includeRol, userSesion } = useContext(GeneralContext);
  

  const [loading, setLoading] = useState(false);
  const acceptPrefacture = (periodo) => {
  setLoading(true);   
  axios
      .acceptPrefactura(data.id_prefactura)
      .then((response) => {
        fetch(periodo);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });

  
}


  const navigate = useNavigate();
  const [loadingPDF, setLoadingPDF] = useState(false);

  const downloadPDF = (url) => {
    setLoadingPDF(true);
    window.open(url, '_blank');
  };

  return (
    <>
<Card sx={{ width: "100%", marginRight: 2, marginBottom: 2, padding: 0, border: "3px solid #003B8D", borderRadius: "1.25rem" }}>
  <CardHeader
    sx={{ padding: 1.5, borderBottom: "1px solid #0000001F" }}
    avatar={
      <Avatar
        src={data.avatar}
        sx={{ bgcolor: 'primary.main', borderRadius: 2, width: 40, height: 40 }}
      />
    }
    title={
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" fontWeight="bold">{data.nombre_operador}</Typography>
        <Box display="inline-flex" alignItems="center" gap={1}>
          <Chip label={"Facturación"} size="small" sx={{ backgroundColor: "#3D6CAE", color: "#FFF" }} />
          <Chip label={data.estado_implementacion} size="small" sx={{ backgroundColor: "#3D6CAE33" }} />
        </Box>
      </Box>
    }
    action={
      <Grid container sx={{ height: "100%", marginBottom: "-8px", marginTop: "8px" }}>
        <Grid
          sx={{
            borderLeft: "1px solid #0000001F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 1.5,
            paddingX: 1,
          }}
        >
          <PageButtonSimple
            disabled={!data.link_prefactura_sagec}
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_sagec);
            }}
          >
            <Stack>
            <span>SAGEC prefactura</span>
            <span><b>{data.version ? "V"+data.version : ""}</b> - {data.fecha_creacion.split("T")[0]}</span>
            </Stack>
          </PageButtonSimple>
        </Grid>
        <Grid
          sx={{
            borderLeft: "1px solid #0000001F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 1.5,
            paddingX: 1,
          }}
        >
          <PageButtonSimple
            disabled={!data.link_prefactura_operador}
            width="10rem"
            pad="0.5rem"
            fs="0.6875rem"
            startIcon={<DownloadIcon />}
            onClick={(e) => {
              e.stopPropagation();
              downloadPDF(data.link_prefactura_operador);
            }}
          > 
            <Stack>
            <span>Operador prefactura</span>
            <span>{ data.fecha_creacion ? data.fecha_creacion.split("T")[0] : "-"}</span>
            </Stack>
          </PageButtonSimple>
        </Grid>
        
        <Grid
          sx={{
            borderLeft: "1px solid #0000001F",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingY: 1.5,
            paddingX: 1,
          }}
        > 

          <Typography variant="body2" color="textSecondary" sx={{marginX: "0.5rem"}}><b>Periodo: {data.periodo}</b></Typography>
          <Typography variant="body2" color="textSecondary" component="div" >
            <Box display="flex" alignItems="center" mr={1}>
              <FiberManualRecordIcon
                sx={{
                  fontSize: ".75rem",
                  color:
                    data.estado_validacion === "En Apelación"
              ? "#F9961E" : data.estado_validacion === "Pendiente"
                ? "#FFE263"
                : data.estado_validacion.startsWith("Rechazado")
                ? "#FB3B3B"
                : "#41AE43",
                  marginX: "0.5rem",
                }}
              />
              {data.estado_validacion}
            </Box>
          </Typography>
          <Chip label={data.diff} size="small" sx={{ backgroundColor: data.color, color: data.diferencia ? "white" : "" }} />
        </Grid>
      </Grid>
    }
  />



      
  <Box sx={{padding: 1}}>
      <TableContainer
        sx={{
          padding: 0,
          maxHeight: '20rem',
          overflow: 'auto',
          scrollbarWidth: 'thin', 
          '&::-webkit-scrollbar': { width: '8px' }, 
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Table size="small" stickyHeader sx={{borderCollapse: "collapse"}}>
          <TableHead sx={{backgroundColor: "#FFF"}}>
            <TableRow >
              <TableCell >Zona</TableCell>
              <TableCell >Etapas</TableCell>
              <TableCell >Bloques</TableCell>
            </TableRow>
          </TableHead>
                <tbody>
{data.zonas && data.zonas.map((zonaItem, zonaIndex) => {
  const hasDifferences = zonaItem.etapas?.some(etapa =>
    etapa.bloques?.some(bloque => bloque.rbd_diferencias_list.length > 0)
  );

  return (
    <React.Fragment key={zonaIndex}>
      {zonaItem.etapas && zonaItem.etapas.map((etapaItem, etapaIndex) => (
        <tr key={etapaIndex}>
          {etapaIndex === 0 && (
            <td
              align="center"
              rowSpan={zonaItem.etapas.length}
              style={{
                border: "1px solid rgba(0, 0, 0, 0.30)",
                borderRight: "none",
                borderLeft: "none",
                backgroundColor: hasDifferences && data.estado_implementacion==="Implementacion" ? "#9C48B933" : "transparent", // Color si cumple condición
              }}
            >
              {zonaItem.nombre_zona}
            </td>
          )}
          <td
            align="center"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.30)",
              borderRight: "none",
            }}
          >
            {etapaItem.nombre_etapa}
          </td>
          <td
            align="center"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.30)",
              padding: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0px",
                width: "100%",
              }}
            >
              {etapaItem.bloques &&
                etapaItem.bloques.map((bloque, bloqueIndex, bloqueArray) => (
                  <React.Fragment key={bloqueIndex}>
                    <span
                      style={{
                        flex: 1,
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: bloque.rbd_diferencias_list.length > 0
                          ? "#9C48B933"
                          : "transparent",
                        color: "black",
                        borderRight:
                          bloqueIndex < bloqueArray.length - 1
                            ? "1px solid rgba(0, 0, 0, 0.30)"
                            : "none",
                      }}
                    >
                      {bloque.nombre_bloque}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
})}

</tbody>
        </Table>
      </TableContainer>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ padding: 2 }}>
      <Grid container spacing={1} justifyContent="space-between">
      <Grid size={6}>
        <Typography variant="body2" align="left" color="textSecondary" sx={{ mb: 2 }} >
          <b>Código de aprobación prefactura: -</b>
        </Typography>
      </Grid>
      <Grid size={6}>
        <Table>
<TableBody>
  {saldoData.map((row, index) => (
    <TableRow key={index} sx={{ borderBottom: "none" }}>
      <TableCell sx={{ borderBottom: "none", paddingBottom: 1, paddingTop: 0  }}>
        <Typography
          variant="body2"
          align="right"
          sx={{ fontWeight: index === saldoData.length - 1 ? "bold" : "normal" }}
        >
          {row.label}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        sx={{ borderBottom: "none", paddingBottom: 1, paddingTop: 0 }}
      >
        <Typography
          variant="body2"
          align="right"
          sx={{ fontWeight: index === saldoData.length - 1 ? "bold" : "normal" }}
        >
          {row.value}
        </Typography>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
</Table>
      </Grid>

      </Grid>

  
        { includeRol([ROL_OP_ADMIN, ROL_ADMIN]) ?
        (<Grid container spacing={1} justifyContent="space-between">
          <Grid size={6}>
            <PageButtonWhite
              loading={data.estado_validacion !== "Pendiente" || loading}
              width="100%"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                acceptPrefacture(data.periodo);
              }}
            >
              Aceptar prefactura 
            </PageButtonWhite>
          </Grid>
          <Grid size={6}>
            <PageButtonBlack
              loading={data.estado_validacion !== "Pendiente"}
              width="100%"
              pad="0.5rem 1rem"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/apelacion/" + data.periodo)
              }}
            >
              Apelar pre-factura
            </PageButtonBlack>
          </Grid>
        </Grid>)

         : null }
      </Box>
    </Card>


    {
      dataDev ? (
       <Card sx={{ width: "100%", marginRight: 2, marginBottom: 2, padding: 0, border: "3px solid #FFCCBC", borderRadius: "1.25rem" }}>
  <CardHeader
    sx={{ padding: 1.5, borderBottom: "1px solid #0000001F" }}
    avatar={
      <Avatar
        src={data.avatar}
        sx={{ bgcolor: 'primary.main', borderRadius: 2, width: 40, height: 40 }}
      />
    }
    title={
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" fontWeight="bold">{data.nombre_operador}</Typography>
        <Box display="inline-flex" alignItems="center" gap={1}>
          <Chip label={"Devengo"} size="small" sx={{ backgroundColor: "#FE9D7E", color: "#FFF" }} />
          <Chip label={dataDev.estado_implementacion} size="small" sx={{ backgroundColor: "#FE9D7E33" }} />
        </Box>
      </Box>
    }
    action={
      <Box sx={{
        display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingY: 1.5,
            paddingX: 1,
          }} >
                    <Typography variant="body2" color="textSecondary" sx={{marginX: "0.5rem", marginBottom: "0.2rem"}}><b>Periodo: {dataDev.periodo}</b></Typography>

          <Chip label={dataDev.diff} size="small" sx={{ backgroundColor: dataDev.color, color: dataDev.diferencia ? "white" : "" }} />

          </Box>
    }
  />


      
<Box sx={{padding: 1}}>
      <TableContainer
        sx={{

          padding: 1,
          maxHeight: '20rem',
          overflow: 'auto',
          scrollbarWidth: 'thin', 
          '&::-webkit-scrollbar': { width: '8px' }, 
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Table size="small" stickyHeader sx={{borderCollapse: "collapse"}}>
          <TableHead>
            <TableRow>
              <TableCell>Zona</TableCell>
              <TableCell>Etapas</TableCell>
              <TableCell>Bloques</TableCell>
            </TableRow>
          </TableHead>
                <tbody>
 {dataDev.zonas && dataDev.zonas.map((zonaItem, zonaIndex) => {
  const hasDifferences = zonaItem.etapas?.some(etapa =>
    etapa.bloques?.some(bloque => bloque.rbd_diferencias_list.length > 0)
  );

  return (
    <React.Fragment key={zonaIndex}>
      {zonaItem.etapas && zonaItem.etapas.map((etapaItem, etapaIndex) => (
        <tr key={etapaIndex}>
          {etapaIndex === 0 && (
            <td
              align="center"
              rowSpan={zonaItem.etapas.length}
              style={{
                border: "1px solid rgba(0, 0, 0, 0.30)",
                borderRight: "none",
                borderLeft: "none",
                backgroundColor: hasDifferences && dataDev.estado_implementacion==="Implementacion" ? "#9C48B933" : "transparent", // Color si cumple condición
              }}
            >
              {zonaItem.nombre_zona}
            </td>
          )}
          <td
            align="center"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.30)",
              borderRight: "none",
            }}
          >
            {etapaItem.nombre_etapa}
          </td>
          <td
            align="center"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.30)",
              padding: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0px",
                width: "100%",
              }}
            >
              {etapaItem.bloques &&
                etapaItem.bloques.map((bloque, bloqueIndex, bloqueArray) => (
                  <React.Fragment key={bloqueIndex}>
                    <span
                      style={{
                        flex: 1,
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: bloque.rbd_diferencias_list.length > 0
                          ? "#9C48B933"
                          : "transparent",
                        color: "black",
                        borderRight:
                          bloqueIndex < bloqueArray.length - 1
                            ? "1px solid rgba(0, 0, 0, 0.30)"
                            : "none",
                      }}
                    >
                      {bloque.nombre_bloque}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
})}
</tbody>
        </Table>
      </TableContainer>
      </Box>

     
    </Card>) : null
    }

   
    </>
  );
}
