import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import PageBody from "../GeneralComponents/PageBody";
import ParqueAdd from "./ParqueAdd";
import ParqueDelete from "./ParqueDelete";
import ParqueEditDate from "./ParqueEditDate";
import ParqueTech from "./ParqueTech";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import { ROL_VAL_SUBTEL} from "../../Constants/Constants";


export default function Parque() {
    const { setPageTitle, setDrawerHidding, includeRol } = useContext(GeneralContext);
    const [activeSection, setActiveSection] = useState("editDate");
    const sectionRefs = {
        editDate: useRef(null),
        delete: useRef(null),
        add: useRef(null),
        tech: useRef(null),
    };

    useEffect(() => {
        setDrawerHidding(false);

        const handleScroll = () => {
            const sections = Object.keys(sectionRefs);
            for (const section of sections) {
                const ref = sectionRefs[section].current;
                if (ref && ref.getBoundingClientRect().top <= window.innerHeight / 2 && ref.getBoundingClientRect().top >= -ref.clientHeight / 2) {
                    setActiveSection(section);
                    break;
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScrollToSection = (section) => {
  setActiveSection(section);

  const sectionOffset = sectionRefs[section].current.offsetTop;

  window.scrollTo({
    top: sectionOffset - 110,
    behavior: "smooth",
  });
};

    return (
        <PageBody>
            <Box
                sx={{
                    position: "sticky", 
                    top: "50px", 
                    backgroundColor: "#F6F6F6",
                    zIndex: 1000,
                    padding: "1rem",
                    marginBottom: "1rem",
                    width: "100%",
                    transition: "top 0.3s ease-in-out",
                }}
            >
                <Stack direction="row" spacing={2} justifyContent="center">
                    {activeSection === "editDate" ? (
                    <PageButtonBlack onClick={() => handleScrollToSection("editDate")} width="auto" >
                      Agregar fechas de inicio servicio / RxO
                    </PageButtonBlack>
                  ) : (
                    <PageButtonWhite onClick={() => handleScrollToSection("editDate")} width="auto" >
                      Agregar fechas de inicio servicio / RxO
                    </PageButtonWhite>
                  )}
                   {activeSection === "delete" ? (
                    <PageButtonBlack
                      onClick={() => handleScrollToSection("delete")} width="auto" >
                      Eliminar EES del Parque
                    </PageButtonBlack>
                  ) : (
                    <PageButtonWhite
                      onClick={() => handleScrollToSection("delete")} width="auto" >
                      Eliminar EES del Parque
                    </PageButtonWhite>
                  )}

                  {activeSection === "add" ? (
                    <PageButtonBlack onClick={() => handleScrollToSection("add")} width="auto" >
                      Modificar EES
                    </PageButtonBlack>
                  ) : (
                    <PageButtonWhite onClick={() => handleScrollToSection("add")} width="auto" >
                      Modificar EES
                    </PageButtonWhite>
                  )}
                  { includeRol([ROL_VAL_SUBTEL]) ? (activeSection === "tech" ? (
                    <PageButtonBlack onClick={() => handleScrollToSection("tech")} width="auto" >
                      Cambio de Tecnología
                    </PageButtonBlack>
                  ) : (
                    <PageButtonWhite onClick={() => handleScrollToSection("tech")} width="auto" >
                      Cambio de Tecnología
                    </PageButtonWhite>
                  )) : null}
                </Stack>
            </Box>

            

            <Box ref={sectionRefs.editDate} sx={{ marginBottom: "2rem" }}>
                <ParqueEditDate />
            </Box>

            <Box ref={sectionRefs.delete} sx={{ marginBottom: "2rem" }}>
                <ParqueDelete />
            </Box>

            <Box ref={sectionRefs.add} sx={{ marginBottom: "2rem" }}>
                <ParqueAdd />
            </Box>

            { includeRol([ROL_VAL_SUBTEL]) ?
            (<Box ref={sectionRefs.tech} sx={{ marginBottom: "2rem" }}>
                <ParqueTech />
            </Box>) : null }
        </PageBody>
    );
}