import './App.css';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import EES from "./Components/EES/EES";
import Validador from "./Components/Validador/Validador";
import M2M from "./Components/M2M/M2M";
import Fus from "./Components/Fus/Fus";
import Fas from "./Components/Fas/Fas";
import Contingencias from "./Components/Contingencias/Contingencias";
import ContingenciasList from "./Components/Contingencias/ContingenciasList";
import ContingenciasPreview from "./Components/Contingencias/ContingenciasPreview";
import ConsultaS from "./Components/ConsultaS/ConsultaS";
import ConsultaM from "./Components/ConsultaM/ConsultaM";
import NotFound from "./Components/NotFound/NotFound";
import DrawerBase from './Components/Drawer/Drawer';
import SignIn from './Components/Login/SignIn';
import Test from './Components/AuthT/AuthT';
import RequireAuth from './hooks/RequireAuth';
import ProtectedRoute from './hooks/ProtectedRoute';
import ContextProvider from './Components/GeneralContext/ContextProvider';
import { SnackbarProvider } from "./Components/GeneralContext/SnackbarContext";
import UUID from "./Components/UUID/UUID";
import Support from "./Components/Support/Support";
import Home from "./Components/Home/Home";
import UserConf from "./Components/Configuracion/Configuracion";
import GobStyle from './styles/GobStyle';
import FasList from './Components/Fas/FasList';
import FasFirma from './Components/Fas/FasFirma';
import FasPreview from './Components/Fas/FasPreview';
import FusList from './Components/Fus/FusList';
import FusFirma from './Components/Fus/FusFirma';
import FusPreview from './Components/Fus/FusPreview';
import ContingenciasFirma from './Components/Contingencias/ContingenciasFirma';
import Monitoreo from './Components/Reports/Monitoreo';
import Tendencias from './Components/Reports/Tendencias';
import PrefacturaList from './Components/Prefactura/PrefacturaList';
import PrefacturaDetails from './Components/Prefactura/PrefacturaDetails';
import Apelacion from './Components/Prefactura/Apelacion';
import ApelacionValidacion from './Components/Prefactura/ApelacionValidacion';
import ApelacionPreview from './Components/Prefactura/ApelacionPreview';
import ApelacionesList from './Components/Prefactura/ApelacionesList';
import Parque from './Components/Parque/Parque';
import ParqueList from './Components/Parque/ParqueList';
import CheckFile from './Components/CheckFile/CheckFile';
import PrefacturaHistory from './Components/Reports/PrefacturaHistory'
import OPReport from './Components/Reports/OPReport'



function App() {
  return (
    <div>
      <BrowserRouter>
        <GobStyle>
        <SnackbarProvider>
          <ContextProvider>
            <DrawerBase>
              <Routes>


              
                <Route path="*" element={<NotFound />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/" element={<Navigate replace to="/login" />} />
           
                
                <Route element={<RequireAuth />}>

                  <Route path="/contingencias" element={<ProtectedRoute requiredResource="/contingencias"><Contingencias />  </ProtectedRoute>} />
                  <Route path="/fas_validacion" element={<ProtectedRoute requiredResource="/fas_validacion"> <Validador /> </ProtectedRoute>} />
                  <Route path="/fas_lista" element={<ProtectedRoute requiredResource="/fas_lista"> <FasList /> </ProtectedRoute>} />
                  <Route path="/fus" element={<ProtectedRoute requiredResource="/fus"> <Fus /> </ProtectedRoute>} />
                  <Route path="/fus_lista" element={<ProtectedRoute requiredResource="/fus_lista"> <FusList /> </ProtectedRoute>} />
                  <Route path="/fas" element={<ProtectedRoute requiredResource="/fas"> <Fas /> </ProtectedRoute>} />
                  <Route path="/uuid" element={<ProtectedRoute requiredResource="/uuid"> <UUID /> </ProtectedRoute>} />
                  <Route path="/configuracion" element={<UserConf />} />
                  <Route path="/ayuda" element={<Support />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/test_auth" element={<Test />} />

                  <Route path="/contingencias_lista" element={<ProtectedRoute requiredResource="/contingencias_lista"> <ContingenciasList /> </ProtectedRoute>}  />
                  <Route path="/contingencias_detalle/:id" element={<ProtectedRoute requiredResource="/contingencias_lista"><ContingenciasPreview />  </ProtectedRoute>} />
                  <Route path="/fas_firma/:id" element={<ProtectedRoute requiredResource="/fas_lista"><FasFirma />  </ProtectedRoute>} />
                  <Route path="/fus_firma/:id" element={<ProtectedRoute requiredResource="/fus_lista"><FusFirma />  </ProtectedRoute>} />
                  <Route path="/fas_detalle/:id" element={<ProtectedRoute requiredResource="/fas_lista"><FasPreview />  </ProtectedRoute>} />
                  <Route path="/fus_detalle/:id" element={<ProtectedRoute requiredResource="/fus_lista"><FusPreview />  </ProtectedRoute>} />
                  <Route path="/contingencias_firma/:id" element={<ProtectedRoute requiredResource="/contingencias_lista"><ContingenciasFirma />  </ProtectedRoute>} />

                   <Route path="/prefactura" element={<ProtectedRoute requiredResource="/prefactura"> <PrefacturaList /> </ProtectedRoute>}  />
                   <Route path="/prefactura_detalle/:id" element={<ProtectedRoute requiredResource="/prefactura"> <PrefacturaDetails /> </ProtectedRoute>}  />
                  <Route path="/apelacion_validacion/:id" element={<ProtectedRoute requiredResource="/prefactura"><ApelacionValidacion/> </ProtectedRoute>} />
                  <Route path="/devengo_validacion/:id" element={<ProtectedRoute requiredResource="/prefactura"><ApelacionValidacion/> </ProtectedRoute>} />

                   <Route path="/apelacion_detalle/:id" element={<ProtectedRoute requiredResource="/apelaciones_lista"> <ApelacionPreview /> </ProtectedRoute>}  />
                   <Route path="/devengo_detalle/:id" element={<ProtectedRoute requiredResource="/apelaciones_lista"> <ApelacionPreview /> </ProtectedRoute>}  />

                   <Route path="/prefactura_detalle" element={<ProtectedRoute requiredResource="/prefactura_detalle"> <PrefacturaDetails /> </ProtectedRoute>}  />
                  <Route path="/apelacion/:periodo" element={<ProtectedRoute requiredResource="/prefactura_detalle"><Apelacion />  </ProtectedRoute>} />
                  <Route path="/devengo/:periodo" element={<ProtectedRoute requiredResource="/prefactura_detalle"><Apelacion />  </ProtectedRoute>} />
                   <Route path="/apelaciones_lista" element={<ProtectedRoute requiredResource="/apelaciones_lista"><ApelacionesList /> </ProtectedRoute> } />

                  <Route path="/verificador" element={<CheckFile />} />
                  <Route path="/supervision_operador" element={<ProtectedRoute requiredResource="/supervision_operador"><OPReport /></ProtectedRoute>} />
                  <Route path="/prefactura_historial" element={<ProtectedRoute requiredResource="/prefactura_historial"><PrefacturaHistory/>  </ProtectedRoute>} /> 

                  <Route path="/parque_lista" element={<ProtectedRoute requiredResource="/parque_lista"><ParqueList/>  </ProtectedRoute>} />

                  <Route path="/parque" element={<ProtectedRoute requiredResource="/parque"><Parque />  </ProtectedRoute>} />
                </Route>
              </Routes>
            </DrawerBase>
          </ContextProvider>
          </SnackbarProvider>
        </GobStyle>
      </BrowserRouter>
    </div>
  );
}


export default App;
