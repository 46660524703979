import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper, Checkbox } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { useNavigate } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CircularProgress } from '@mui/material';
import ListStepper from "../GeneralComponents/ListStepper";
import ListEmpty from "../GeneralComponents/ListEmpty";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";


import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, REJECTED, APROVED, ROL_ADMIN, ROL_OP_ADMIN, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_CON_MINEDUC, ROL_MINEDUC_ADMIN} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "",
    width: "5%",
    minWidth: "50px",
    align: "center",
  },
{ id: "folio", label: "ID folio", width: "12%", minWidth: "100px"},
  { id: "periodo", label: "Periodo", width: "12%", minWidth: "120px"},
  { id: "rut_emisor", label: "Rut Emisor", width: "12%", minWidth: "120px" },
  {
    id: "estado_resumen",
    label: "Estado",
    width: "16%",
    minWidth: "160px",
    align: "left",
  },
  {
    id: "firma",
    label: "Firma",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "detalle",
    label: "Detalle Documento",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "ruta_url",
    label: "Documento",
    width: "10%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "operador",
    label: "Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];




export default function ContingenciasList({ prefilter = false }) {
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loadingTable, setLoadingTable] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [selected, setSelected] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const navigate = useNavigate();

  const firmasPendientes = [ NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC ];

  const rolesFirma = {
    EES: [ NO_EES, NO_EES_TEMP ],
    SUBTEL: [ NO_SUBTEL ],
    MINEDUC: [ NO_MINEDUC ],
  };

function getSignatureText(currentState) {

    let inst = "FAS";
    if (includeRol([ROL_REP_EES, ROL_REP_EES_TEMP])) {
        inst = "EES";
    } else if (includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL])) {
        inst = "SUBTEL";
    } else if (includeRol([ROL_MINEDUC_ADMIN, ROL_CON_MINEDUC])) {
        inst = "MINEDUC";
    }

    const currentStep = firmasPendientes.indexOf(currentState);

    const userSteps = rolesFirma[inst] || [];

    const userStepsIndex = firmasPendientes.findIndex((etapa) =>
        userSteps.includes(etapa)
    );
    if (userSteps.includes(currentState)) {
        return "Firmar";
    }

    if (userStepsIndex > currentStep) {
        return "Firmar";
    }
    return "Firmado";
}

  const canSign = (estado) => {
    if (
      (estado === NO_EES || estado === NO_EES_TEMP) &&
      includeRol([ROL_REP_EES, ROL_REP_EES_TEMP])
    ) {
      return true;
    } else if (
      estado === NO_SUBTEL &&
      includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL])
    ) {
      return true;
    } else if (
      estado === NO_MINEDUC &&
      includeRol([ROL_MINEDUC_ADMIN, ROL_CON_MINEDUC])
    ) {
      return true;
    }

    return false;
  };


    const downloadPDF = (url) => {
      setLoadingPDF(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = blobUrl;
                a.download = 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {setLoadingPDF(false); console.error('Error al descargar el archivo:', error); window.open(url, '_blank'); });
    };

    const downloadZip = async () => {
      try {
        if (selected.length === 0){
          return
        }
        setDownloadLoader(true);
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}_${String(now.getHours()).padStart(2, "0")}-${String(now.getMinutes()).padStart(2, "0")}-${String(now.getSeconds()).padStart(2, "0")}`;
        
        const response = await axios.downloadDocuments("contingencias", selected);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contingencias_${timestamp}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error("Error al descargar documentos:", error);
    }
    setDownloadLoader(false);
  };


  const fetchData = async () => {
    setLoadingTable(true);
    axios
      .getContingenciasData()
      .then((response) => {
        const modifiedData = response.data
        .filter((row) => !prefilter || row.estado === "Pendiente")
        .map((row) => {
          return {
            ...row,
            periodo: row.periodo.slice(0, 7),
            fecha: row.periodo.split("T")[0],
            estado_resumen:
              row.estado !== APROVED && row.estado !== REJECTED
                ? "En Proceso"
                : row.estado,
            puede_firmar: canSign(row.estado),
            firma: "",
            checkbox: "",
            detalle: "",
            folio: String(row.id).padStart(8, '0')
          };
        });


        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

    if (
      includeRol([ROL_REP_EES, ROL_REP_EES_TEMP, ROL_MINEDUC_ADMIN ])
    ) {
      const columnOrder = [
            "checkbox",
            "periodo",
            "operador",
            "estado_resumen",
            "firma",
            "detalle",
            "ruta_url",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
    } else if (includeRol([ROL_ADMIN_SUBTEL, ROL_MINEDUC_ADMIN])) {
       const columnOrder = [
            "checkbox",
            "periodo",
            "operador",
            "folio",
            "estado_resumen",
            "firma",
            "detalle",
            "ruta_url",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    } else if (includeRol([ROL_OP_ADMIN, ROL_VAL_SUBTEL, ROL_ADMIN])) {
       const columnOrder = [
            "checkbox",
            "periodo",
            "folio",
            "estado_resumen",
            "firma",
            "detalle",
            "ruta_url",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;

    if (value == null) {
        delete filterObj[name];
    }

    const newTableData = Object.keys(filterObj).length !== 0
        ? rows.filter((item) =>
            Object.entries(filterObj).every(([key, value]) => item[key] === value)
        )
        : rows;

    if (JSON.stringify(newTableData) !== JSON.stringify(tableData)) {
        setSelected([]);
    }

    setFilters(filterObj);
    setTableData(newTableData);
};


    const handleSelectAll = (event) => {
  if (event.target.checked) {
    const newSelecteds = tableData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .filter((n) => n.estado_resumen === APROVED) // Filtrar por estado
      .map((n) => n.id); // Obtener solo los ids
    setSelected(newSelecteds);
  } else {
    setSelected([]);
  }
};

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;



  useEffect(() => {
    setPageTitle("Listado de contingencias");
  }, [pageTitle, setPageTitle]);

  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {}, []);


  function transformData(data) {

          return {
            ...data,
            progress: {
            state: data.estado,
              steps: data.firmas.map(firma => ({
                  type: firma.estado_firma,
                  institution: {
                      type: ["MINEDUC", "SUBTEL", "EES"].find(role => firma.rol_firma.includes(role)) || "Contingencia",
                      name: ["MINEDUC", "SUBTEL", "EES"].find(role => firma.rol_firma.includes(role)) || "Contingencia",
                      rol_firma: firma.rol_firma
                  },
                  in_charge: [firma.nombre]  
              }))
          }
        }
  }
  const isItemSelected = isSelected(row.id);
    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)} 
          hover
          role="checkbox"
          tabIndex={-1}
          key={"TR" + index}
          aria-checked={isItemSelected}
          key={row.index}
          selected={isItemSelected}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >

          {filteredColumns.map((column, index) => {
            const value = row[column.id];
            return (
              <TableCell key={"TC" + index} align={column.align}>
                {column.id === "ruta_url" ? (
                  <Button
                  disabled={loadingPDF || row["estado_resumen"] !== APROVED }
                    onClick={(e) => { e.stopPropagation();
                      downloadPDF(value)
                    }}
                    key={`${column.id}.button.${index}`}
                    variant="text"
                    startIcon={
                      <PictureAsPdfIcon
                        key={`${column.id}.icon.${index}`}
                        sx={{ color: loadingPDF || row["estado_resumen"] !== APROVED ? "grey" : "#111" }}
                      />
                    }
                  >
                    <Stack>
                      <Typography
                        key={`${column.id}.typ.${index}`}
                        sx={{
                          color: loadingPDF || row["estado_resumen"] !== APROVED ? "grey" : "#111",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                        }}
                      >
                        Descargar
                      </Typography>
                    </Stack>
                  </Button>
                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                    disabled={row.estado_resumen !== APROVED}
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.id)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.id,
                                      }}
                                    />
                                  ) : column.id==="detalle" ? (
                  <Button
                    onClick={(e) => { 
                      e.stopPropagation();
                      navigate('/contingencias_detalle/'+row["id"]);
                    }}
                    key={`${column.id}.button.${index}`}
                    variant="text"
                  >
                    <Typography
                      key={`${column.id}.typ.${index}`}
                      sx={{
                        color: "#111",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                    >
                      Ver
                    </Typography>
                  </Button>
                ) : column.id.startsWith("firma") && (row["estado_resumen"] !== REJECTED && row["estado_resumen"] !== APROVED) ? (
                  <PageButtonBlack
                    width="10rem"
                    loading={!row["puede_firmar"]}
                    pad="0 1rem"
                    onClick={(e) => { 
                      e.stopPropagation();
                      navigate("/contingencias_firma/"+row["id"]);
                    }}
                  >
                    { getSignatureText(row['estado']) }
                  </PageButtonBlack>
                ) :

                column.id === 'estado_resumen' ? (
                
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'no-wrap',
                    }}>
                      <FiberManualRecordIcon sx={{ fontSize: "0.75rem", color: value === 'En Proceso' ? '#FFE263' : value === APROVED ? '#41AE43' : '#FB3B3B' , marginRight: "0.5rem" }} />

                      {value}
                      <Box sx={{ flexGrow: 1 }} />
                      {value !== 'En Proceso' ? null : open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </div>

                )
                :
                 (
                  value
                )}

                
              </TableCell>
            );
          })}
        </TableRow>
        {row["estado_resumen"] === "En Proceso" ? (
          <TableRow key={row.code + 1}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <ListStepper
                    row={transformData(row)}
                    source="Contingencias"
                    formData={row}
                  ></ListStepper>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  return (
    <PageBody>
      <Box width="100%">
        {includeRol([
          ROL_OP_ADMIN,
          ROL_REP_EES,
          ROL_REP_EES_TEMP,
          ROL_VAL_SUBTEL,
          ROL_ADMIN,
          ROL_CON_MINEDUC, 
          ROL_MINEDUC_ADMIN
        ]) ? (
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "-2rem" }}
          >
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"periodo"}
                name={"Periodo"}
                callback={filterRecords}
              />
            </Grid>
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"estado"}
                name={"Estado"}
                callback={filterRecords}
              />
            </Grid>
            {includeRol([
              ROL_REP_EES,
              ROL_REP_EES_TEMP,
              ROL_VAL_SUBTEL,
              ROL_ADMIN,
            ]) ? (
              <Grid size={3}>
                <InputFilter
                  data={rows}
                  item={"folio"}
                  name={"ID folio"}
                  callback={filterRecords}
                />
              </Grid>
            ) : null}
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"operador"}
                name={"Operador"}
                callback={filterRecords}
              />
            </Grid>
            
          </Grid>
        ) : includeRol([ROL_ADMIN_SUBTEL]) ? (
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "17px" }}
          >
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"operador"}
                name={"Operador"}
                callback={filterRecords}
              />
            </Grid>
            <Grid size={3}>
              <InputFilter
                data={rows}
                item={"periodo"}
                name={"Periodo"}
                callback={filterRecords}
              />
            </Grid>
          </Grid>
        ) : null}

        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            background: "#FFFFFF",
            borderRadius: "0.25rem",
            marginBottom: "0.75rem",
          }}
        >
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ textAlign: "center", padding: "0.5rem" }}
          >
            LISTADO CONTINGENCIAS
          </Typography>
        </Paper>


        {loadingTable ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
          <>
        <PageBgBlock pad="0">
          <Paper
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <TableContainer>
              <Table size="small" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {filteredColumns.map((column, index) => (
                      <TableCell
                        key={"CL" + index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < tableData.filter((n) => n.estado_resumen === APROVED).length
                                }
                                checked={
                                  selected.length > 0 &&
                                  selected.length ===  tableData.filter((n) => n.estado_resumen === APROVED).length
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row key={index} row={row} index={index} />
                    ))}
                </TableBody>
              </Table>
                    {tableData.length === 0 ? (<ListEmpty></ListEmpty>) : null}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>




        </PageBgBlock>
          <Grid container >
          <Grid size={7}>
           
          </Grid>
          <Grid size={5}>
            <PageButtonWhite
              loading={downloadLoader || selected.length === 0}
              onClick={downloadZip}
              width="100%"
            >
              Descarga selección de documentos (.zip)
            </PageButtonWhite>
          </Grid>
          </Grid>
          </>

          }
       
      </Box>
    </PageBody>
  );
}
