import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";


import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";


import { ROL_VAL_MINEDUC, ROL_REP_EES, ROL_VAL_SUBTEL} from "../../Constants/Constants";


export default function EmbeddedOperadorGrid() {
  const { includeRol, userSesion } = useContext(GeneralContext);

  return (


    <>
       <Grid container spacing={1} sx={{ padding: "0" }}>

          <Grid size={4} sx={{display: "block", minHeight: "300px"}}>
            <iframe width="100%" height="100%" allowFullScreen src="/service/grafana/d-solo/ae4ez5c3t45xcf/home-operador-admin?orgId=1&from=now-1M&to=now&panelId=3" frameBorder="0"></iframe>
          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "300px"}}>
             <iframe width="100%" height="100%" src="/service/grafana/d-solo/ae4ez5c3t45xcf/home-operador-admin?orgId=1&from=now-1M&to=now&panelId=10" frameBorder="0"></iframe>        
          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "300px"}}>
             <iframe width="100%" height="100%" src="/service/grafana/d-solo/ae4ez5c3t45xcf/home-operador-admin?orgId=1&from=now-1M&to=now&panelId=11" frameBorder="0"></iframe>        
          </Grid>


          <Grid size={6} sx={{display: "block", minHeight: "300px"}}>
                <iframe width="100%" height="100%" src="/service/grafana/d-solo/ae4ez5c3t45xcf/home-operador-admin?orgId=1&from=now-1M&to=now&panelId=1" frameBorder="0"></iframe>      
          </Grid>
          <Grid size={6} sx={{display: "block", minHeight: "300px"}}>
                <iframe width="100%" height="100%" src="/service/grafana/d-solo/ae4ez5c3t45xcf/home-operador-admin?orgId=1&from=now-6M&to=now&panelId=6" frameBorder="0"></iframe>   
        </Grid>
      </Grid>
  
    </>
  );
}

